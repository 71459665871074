<script setup lang="ts">
import { capitalize, ref, computed } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { getLastUsedCaptionStyle } from '@/components/Captions/useLocalCaptionSettings'
import { captionStyleSettingsArray, type CaptionStyle } from '@/components/Captions/styles/CaptionStyleManager'
import { intersection } from 'lodash-es'
import { isBefore } from 'date-fns'
import CaptionStylePreview from '@/areas/editor/views/captions/CaptionStylePreview.vue'

const tags = ['all', 'popular', 'playful', 'simple']
const selectedTag = ref('all')

const defaultCaptionStyle = ref(getLastUsedCaptionStyle())
const sortedCaptionsArray = computed(() => captionStyleSettingsArray
  .map((captionStyle) => ({ ...captionStyle, createdAt: captionStyle.createdAt || new Date(0) }))
  .sort((a, b) => {
    if (a.type === defaultCaptionStyle.value) {
      return -1
    } else if (b.type === defaultCaptionStyle.value) {
      return 1
    } else if (intersection(a?.tags, ['christmas', 'halloween']).length > 0) {
      return 1
    } else if (intersection(b?.tags, ['christmas', 'halloween']).length > 0) {
      return -1
    } else if (isBefore(a.createdAt, b.createdAt)) {
      return 1
    } else {
      return -1
    }
  }))

const styles = computed(() => {
  if (selectedTag.value === 'all') {
    return sortedCaptionsArray.value
  } else if (selectedTag.value === 'seasonal') {
    return sortedCaptionsArray.value.filter((style) => intersection(style?.tags, ['christmas', 'halloween']).length > 0)
  } else {
    return sortedCaptionsArray.value.filter((style) => style?.tags?.includes(selectedTag.value))
  }
})

const emit = defineEmits<{
  (event: 'select', captionStyle: CaptionStyle): void
}>()
</script>

<template>
  <div class="shrink-0 w-full min-w-0 overflow-x-auto overflow-y-hidden px-4 py-2">
    <div class="flex flex-row gap-1">
      <RadioToggleButton
        v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag"
        size="sm" class="flex-col relative font-light data-[state=active]:font-semibold  shrink-0"
      >
        <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
        <span class="font-semibold invisible">{{ capitalize(tag) }}</span>
      </RadioToggleButton>
    </div>
  </div>

  <div class="grid gap-2 grid-cols-[repeat(auto-fill,_minmax(130px,_1fr))] px-4">
    <div v-for="(captionStyle, index) in styles" :key="captionStyle.type + index" class="relative">
      <CaptionStylePreview
        show-tier-badge
        @click="emit('select', captionStyle.type)"
        :settings="captionStyle"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
