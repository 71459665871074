import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { v4 as uuid } from 'uuid'
import { useLocalStorage } from '@vueuse/core'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import logging from '@/logging'
import { repairTwitchClipAudio } from '@/areas/editor/startup/repairTwitchClip';

export async function prepareStores(config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.isLoadingClip = true

  config.signal?.throwIfAborted()

  try {
    await startupVideo(config)
    config.signal?.throwIfAborted()

    await startupWithDefaultLayout(config)
    config.signal?.throwIfAborted()

    editorClipInfoStore.isLoadingClip = false
    editorClipInfoStore.loadingState = null

    logging.trackEvent('Clip Imported', {
      source: editorClipInfoStore.source,
    });

    return { error: null }
  } catch (error) {
    return handleStartupError(error)
  }
}

export async function startupVideo(config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Importing video...'
  }
  
  const mp4Url = editorClipInfoStore.mp4Url

  const videoExists = await existsAsync(mp4Url)
  config.signal?.throwIfAborted()

  if (!videoExists) {
    throw fileNotFoundErrorFor(editorClipInfoStore.source)
  } else {

    const videoStore = useVideoStore()

    const video = document.createElement('video')
    video.src = mp4Url
    video.muted = true
    video.autoplay = false
    video.loop = false
    video.controls = true
    video.crossOrigin = 'anonymous'
    video.playsInline = true
    video.disablePictureInPicture = true
    video.disableRemotePlayback = true
    
    const audio = document.createElement('video')
    audio.muted = true
    audio.autoplay = false
    audio.loop = true
    audio.controls = true
    audio.crossOrigin = 'anonymous'
    audio.disableRemotePlayback = true

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Loading video...'
    }

    await videoStore.loadVideo(video, audio, mp4Url).catch((reason) => {
      throw new Error(reason)
    })

    // TODO: We expect this to be removed in the future if Twitch has fixed the issues with Clips.
    const audioMightBeOutOfSync = editorClipInfoStore.mp4Url.startsWith('https://twitch-clips-v2.b-cdn.net/')
    if (audioMightBeOutOfSync) {

      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      if (isFirefox) {
        editorClipInfoStore.loadingState = {
          state: 'loading',
          title: 'Repairing audio 🛠️',
          progressRatio: 0,
          description: 'The current version of Firefox has trouble playing Twitch clips. We are fixing the audio for you. This might take a while. We recommend using Edge or Chrome until Firefox provides a permanent solution.'
        };
        await repairTwitchClipAudio(mp4Url);
      } else {
        repairTwitchClipAudio(mp4Url);
      }
    }

    config.signal?.throwIfAborted()
    videoStore.playing = true
  }
}

export async function startupLayoutsStore() {

  const { prepareLayouts, layouts } = usePresets()

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Preparing layouts and saved templates...'
  }

  await prepareLayouts()
  await new Promise((resolve) => setTimeout(resolve, 0))

  return layouts
}

export async function startupWithDefaultLayout(config: StartupConfig) {

  await startupLayoutsStore()
  config.signal?.throwIfAborted()

  const { layouts } = usePresets()
  const videoStore = useVideoStore()

  const { width, height } = videoStore.videoSize!
  if (Math.abs((width / height) - (9 / 16)) < 0.01) {

    const layoutId = startupWithLayout('full')
    const cropsStore = useCropsStore()
    for (const crop of cropsStore.entities.filter(c => c.layoutId === layoutId)) {
      cropsStore.updateCropAreaById(crop.id, { x: 0, y: 0, width: 1, height: 1 })
    }

    const { currentStep } = useEditorStep()
    currentStep.value = 'editor-elements'
  } else {

    const defaultPreset = useLocalStorage('defaultPreset', 'split')

    const preset = layouts.value.find((l) => l?.id === defaultPreset.value)
    if (!preset) {
      defaultPreset.value = 'split'
    }

    startupWithLayout(preset?.id ?? 'split')
  }
}

function startupWithLayout(presetId: string) {

  const { applyPreset } = usePresets()
  const layoutId = applyPreset(presetId)

  const isMobile = useIsMobile()

  if (!isMobile.value) {

    const cropsStore = useCropsStore()
    const crops = cropsStore.whereLayoutIdIs(layoutId).value

    const editorFocusStore = useEditorFocusStore()
    editorFocusStore.setFocus(FocusTypes.CROP, crops[0].id)
  }

  const segmentsStore = useSegmentsStore()
  segmentsStore.$reset()

  const videoStore = useVideoStore()
  const id = uuid()
  segmentsStore.createById(id, {
    type: 'layout',
    layoutId: layoutId,
    startMs: 0,
    endMs: videoStore.durationMs,
  })

  return layoutId
}

function fileNotFoundErrorFor(source: string) {

  switch (source) {
    case 'twitch-clip':
      return 'Twitch Clip could not be imported.'
    case 'local-file':
      return 'File could not be found.'
    case 'youtube-clip':
      return 'YouTube Clip could not be imported.'
    case 'twitch-vod':
      return 'StreamLadder AI clip could not be imported.'
    case 'kick-clip':
      return 'Kick Clip could not be imported.'
    default:
      return 'Clip could not be imported.'
  }
}

export async function existsAsync(url: string): Promise<boolean> {

  if (!url) {
    return false
  }

  try {
    const response = await fetch(url, { method: url.startsWith('blob:') ? 'GET' : 'HEAD' })
    return response.ok
  } catch (e) {
    return false
  }
}
