<script setup lang="ts">
import { capitalize } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { RadioGroupItem, RadioGroupRoot } from 'radix-vue'
import { Switch } from '@/components/ui/switch'
import { ColorInput } from '@/components/colors'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()

const updateTiming = (value: string | null | undefined) => {
  
  historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_TIMING', () => {
    if (value === 'single') {
      captionsStore.baseOptions.animationTarget = 'word';
    } else if (value === 'group' && captionsStore.baseOptions.animationTarget === 'word') {
      captionsStore.baseOptions.highlight = false;
    }
  });
};

const animationGroupingModel = computed({
  get() {
    return captionsStore.baseOptions.grouping
  },
  set(value) {
    historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_GROUPING', () => {
      captionsStore.baseOptions.grouping = value
    })
  }
});

const animationModel = computed({
  get() {
    return captionsStore.baseOptions.animation
  },
  set(value) {
    historyStore.transaction('CAPTIONS:CHANGE_ANIMATION', () => {
      captionsStore.baseOptions.animation = value
    })
  }
})
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-2 px-4 mt-4">

    <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
      <header class="flex items-start justify-between gap-2 cursor-pointer">
        <div class="flex flex-col gap-0">
          <h3 class="font-semibold text-lg">Display grouping</h3>
          <p class="text-sm text-muted-foreground font-light">Display each word individually or group words into sentences.</p>
        </div>
      </header>

      <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
        <RadioToggleButton
          v-for="option in [{ label: 'Words', value: 'single' }, { label: 'Sentences', value: 'group' }]"
          :key="option.value" :value="option.value"
          v-model="animationGroupingModel"
          @update:model-value="value => updateTiming(value)"
          class="flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
        >
          <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
          <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
        </RadioToggleButton>
      </footer>
    </section>

    <section class="z-20 flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
      <header class="flex items-start justify-between gap-2 cursor-pointer">
        <div class="flex flex-col gap-0">
          <h3 class="font-semibold text-lg">Transitions</h3>
          <p class="text-sm text-muted-foreground font-light">Choose a transition which suits your captions.</p>
        </div>
      </header>

      <footer class="text-black dark:text-white">
        <RadioGroupRoot
          v-model="animationModel" 
          default-value="default"
          aria-label="View density"
        >
          <RadioGroupItem
            id="none"
            value=""
            class="w-full mb-2"
          >
            <div
              class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
              :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === '' }"
            >
              <span>No animation</span>
            </div>
          </RadioGroupItem>

          <div class="grid grid-cols-2 gap-2.5">
            <RadioGroupItem
              id="reveal"
              value="reveal"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'reveal' }"
              >
                <span>Reveal</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="fade-in"
              value="fade-in"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'fade-in' }"
              >
                <span>Fade in</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="slide-right"
              value="slide-right"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'slide-right' }"
              >
                <span>Slide right</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="slide-top"
              value="slide-top"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'slide-top' }"
              >
                <span>Slide top</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="shrink"
              value="shrink"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'shrink' }"
              >
                <span>Shrink</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="bounce"
              value="bounce"
            >
              <div
                class="p-2 border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'bounce' }"
              >
                <span>Bounce</span>
              </div>
            </RadioGroupItem>
          </div>
        </RadioGroupRoot>
      </footer>
    </section>

    <Transition
      enter-from-class="opacity-0 -translate-y-2"
      leave-to-class="opacity-0 -translate-y-2"
      enter-active-class="motion-safe:transition-[transform,_opacity]"
      leave-active-class="motion-safe:transition-[transform,_opacity]"
    >
      <section v-if="captionsStore.baseOptions.grouping !== 'single' && captionsStore.baseOptions.animation !== ''" class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Animation timing</h3>
            <p class="text-sm text-muted-foreground font-light">Choose when to animate your captions.</p>
          </div>
        </header>

        <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
          <RadioToggleButton
            v-for="option in [{ label: 'Word by word', value: 'word' }, { label: 'Sentence by sentence', value: 'sentence' }]"
            :key="option.value" :value="option.value"
            @update:model-value="updateTiming"
            v-model="captionsStore.baseOptions.animationTarget"
            class="truncate text-xs 2xl:text-base flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
          >
            <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
            <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
          </RadioToggleButton>
        </footer>
      </section>
    </Transition>

    <Transition
      enter-from-class="opacity-0 -translate-y-2"
      leave-to-class="opacity-0 -translate-y-2"
      enter-active-class="motion-safe:transition-[transform,_opacity]"
      leave-active-class="motion-safe:transition-[transform,_opacity]"
    >
      <section v-if="captionsStore.baseOptions.grouping !== 'single'" class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="captionsStore.baseOptions.highlight = !captionsStore.baseOptions.highlight">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Highlight current spoken word</h3>
            <p class="text-sm text-muted-foreground font-light">Automatically highlight the current spoken word in your captions.</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.highlight" @click.stop />
        </header>
        <footer v-if="captionsStore.baseOptions.highlight">
          <ColorInput v-model="captionsStore.baseOptions.highlightColor" class="w-full" />
        </footer>
      </section>
    </Transition>
  </div>
</template>

<style scoped lang="scss">
</style>
