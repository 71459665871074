<script setup lang="ts">
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import HsbInput from '@/components/colors/HsbInput.vue'
import { type ClassValue } from 'clsx'
import { cn } from '@/lib/utils'

const props = defineProps<{ disabled?: boolean; class?: ClassValue }>()
const model = defineModel<string>({ required: true })
</script>

<template>
  <Popover>
    <PopoverTrigger as-child>
      <Button variant="toggle" :class="cn('border border-input bg-background min-w-56 justify-start', props.class)">
        <span :style="{ backgroundColor: model }" class="w-6 h-6 border border-border rounded" />
        <span class="text-sm uppercase">{{ model }}</span>
      </Button>
    </PopoverTrigger>
    <PopoverContent>
      <HsbInput v-model:hex="model" />
    </PopoverContent>
  </Popover>
</template>

<style scoped>

</style>
