<script setup lang="ts">
import { computed, ref } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import AccountSelect from '@/components/Account/SelectionRow/AccountSelect.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import ConnectSocialDialog from '@/areas/dashboard/components/ConnectSocialDialog.vue'
import { useVModel } from '@vueuse/core'
import type { ZodIssue } from 'zod'
import { CardContent, CardHeader, Card, CardTitle, CardDescription, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import connectionTypes from '@/enums/connectionTypes'

const props = defineProps<{
  modelValue: string[]
  errors?: ZodIssue[]
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: { selectedAccounts: string[] }): void
}>()

const userInfo = useUserInfoStore()

const selectedIds = useVModel(props, 'modelValue', emit)

const accounts = computed(() => {
  return userInfo.allSocials
    .filter(account => account.displayName && account.profileImageUrl)
    .map((account) => ({
      ...account,
      selected: selectedIds.value.includes(account.id),
    }));
});

const selectedAccounts = computed(() => {
  return accounts.value.filter((account) => account.selected)
})

const hasAccounts = computed(() => accounts.value.length > 0)

const toggleAccount = (id: string) => {
  if (selectedIds.value.includes(id)) {
    selectedIds.value.splice(selectedIds.value.indexOf(id), 1)
  } else {
    selectedIds.value.push(id)
  }
}

const isDialogOpen = ref(false)

const instagramEnabled = useFeatureFlagEnabled('instagram');

const hasInstagramAccount = computed(() => {
  return accounts.value.some((account) => account.type === connectionTypes.INSTAGRAM);
});
</script>

<template>
  <Card
    :class="{
      'border-error bg-red-50': errors && errors.length > 0,
    }"
    v-auto-animate
    v-if="hasAccounts"
  >
    <CardHeader class="relative">
      <CardTitle> Share to</CardTitle>
      <CardDescription>Select the channels you’d like to share towards</CardDescription>
      <Button variant="primary" size="circle" @click="isDialogOpen = true" class="absolute right-5 top-5">
        <IconSaxAdd />
      </Button>
    </CardHeader>
    <CardContent class="flex gap-4 pt-2">
      <account-select
        v-for="account in accounts"
        :key="account.id"
        :account="account"
        :connection-type="account.type"
        :selected="account.selected"
        @click="toggleAccount(account.id)"
      />
    </CardContent>
    <CardFooter>
      <span v-for="error of errors ?? []" :key="error.code" class="text-brand-state-error-text">
        {{ error.message }}
      </span>
    </CardFooter>
    <template v-if="instagramEnabled && !hasInstagramAccount">
      <div class="flex p-8 pt-0">
        <div class="relative hidden md:flex flex-wrap 2xl:flex-nowrap justify-between items-center w-full gap-1 2xl:gap-4 px-4 py-4 2xl:py-5 rounded-lg bg-gradient-to-r from-[#FAE5FF] to-[#DAF1FF]">
          <div class="flex flex-col gap-0.5">
            <span class="flex gap-2 items-center font-semibold text-lg dark:text-black">
              <span class="-mt-0.5 top-2.5 left-2.5 rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background">
                BETA
              </span>
              Connect Instagram
            </span>
            <span class="font-light dark:text-black">
            Connect your Instagram account with Facebook to schedule and post clips
          </span>
          </div>
          <Button variant="primary" class="gap-2" @click="isDialogOpen = true">
            <IconSaxAdd />
            Add
          </Button>
        </div>
      </div>
    </template>
  </Card>
  <div
    v-else
    class="flex flex-col items-center gap-2 rounded-2xl border-2 border-brand-state-hover-border bg-brand-state-hover-bg p-8"
  >
    <LottieAnimation class="w-44 h-44" url="/lottie/cartoon-oh.json" />
    <h3 class="text-style-lg">Connect your socials to plan & schedule clips</h3>
    <p class="text-style-base text-center text-brand-state-text-secondary">
      You can schedule and post clips by <br />
      connecting an account
    </p>
    <Button variant="primary" class="gap-2" size="lg" @click="isDialogOpen = true"
      >Connect Socials
      <IconSaxAdd />
    </Button>
  </div>
  <ConnectSocialDialog v-model="isDialogOpen" />
</template>
