<script lang="ts" setup>
import { type Bindings, bindings } from '@/lib/keybindings';
import { capitalize } from 'vue';
import { isMacOs } from '@/data/isMacOs';

interface Props {
  event: Bindings;
}

const props = defineProps<Props>()

const keys = computed(() => {

  const combination = bindings[props.event];
  const keys = [];

  for (let key of combination[0]?.split('+') ?? []) {
    if (isMacOs) {
      key = key
        .replace('cmd', '⌘')
        .replace('ctrl', '⌃')
        .replace('alt', '⌥')
        .replace('shift', '⇧');
    }

    keys.push(capitalize(key));
  }
  
  return keys;
})
</script>

<template>
  <kbd class="inline-flex items-center pointer-events-none h-5 select-none gap-0.5 rounded border border-border bg-muted font-sans font-medium min-h-5 text-[11px] px-1">
    <template v-for="key of keys" :key="key">
      <span v-if="key === '⌘'" class="text-xs">{{ key }}</span>
      <span v-else-if="key === '⇧'" class="-translate-y-px text-[1.1em]">{{ key }}</span>
      <span v-else>{{ key }}</span>
    </template>
  </kbd>
</template>

<style lang="scss" scoped>

</style>
