<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import CaptionNodeV2 from '@/areas/editor/timeline/tracks/captions/CaptionNodeV2.vue'
import { computed } from 'vue'

const captionsStore = useCaptionsStore();

const captions = computed(() => {
  return captionsStore.entities;
});
</script>

<template>
  <TimeLineTrack class="relative h-full max-h-12 w-full" v-if="captions.length">
    <CaptionNodeV2
      v-for="(caption, captionIndex) in captions"
      :caption="caption"
      :captionIndex="captionIndex"
      :key="caption.id"
    />
  </TimeLineTrack>
</template>
