import type { ClipDto } from '@/areas/editor/@type/ClipDto';
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig';
import type { Clip } from '@/Hooks/useTwitchClips';
import { useWebsocketChannel } from '@/modules/WebsocketService/WebSocketService';
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips';

export async function listenForClipDownload(clip: ClipDto, config?: StartupConfig) {

  return new Promise<Clip>((resolve, reject) => {

    const channelName = `cache-task-status-${clip.taskId}`;
    const timeout = setTimeout(() => {
      channel.value?.disconnect()
      reject(new Error('Clip download timed out.'))
    }, 5 * 60 * 1000)

    const channel = useWebsocketChannel(channelName, (eventName, data) => {

      if (config?.signal?.aborted) {
        channel.value?.disconnect()
        reject(new DOMException('Aborted', 'AbortError'))
        clearTimeout(timeout)
        return
      }

      if (eventName === 'progress') {
        if (data.status === 'finished') {
          abortPollController.abort()
          resolve(data as ClipDto)
          clearTimeout(timeout)
          return
        } else if (data.status === 'error') {
          abortPollController.abort()
          reject(data.message)
          clearTimeout(timeout)
        }
      }
    })

    const abortPollController = new AbortController()
    async function pollClipStatus(clipId: string, options: { wait: number }) {

      await new Promise((resolve) => setTimeout(resolve, options.wait))
      const response = await getApiKickClipsClipId(clipId) as unknown as Clip

      if (config?.signal?.aborted || abortPollController.signal.aborted) {
        throw new DOMException('Aborted', 'AbortError')
      }

      if (response.mp4Url) {
        return response
      } else {
        channel.value?.disconnect()
        return await pollClipStatus(clipId, { wait: 5_000 })
      }
    }

    pollClipStatus(clip.id, { wait: 15_000 }).then((response) => {
      resolve(response)
    });
  })
}
