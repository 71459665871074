<script setup lang="ts">
import { type CaptionStyle, captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset'
import LottieAnimation from '@/components/LottieAnimation.vue'
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import { onMounted } from 'vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { CaptionEditorTabs } from '@/areas/editor/views/captions/tabs'
import CaptionV2StyleSelector from '@/areas/editor/views/captions/v2/CaptionV2StyleSelector.vue'
import type { CaptionStyleDefinition } from '@/components/Captions/captionTypes'

const legacyCaptionsStore = useEditorCaptionsStore();
const captionsStore = useCaptionsStore();
const historyStore = useHistoryStore();

const onSelect = async (style: CaptionStyle) => {
  const preset = captionStyleToCaptionPreset(style) as CaptionPreset;
  const captionStyleDefinition = captionStylesSettings[style] as CaptionStyleDefinition
  await historyStore.transaction('CAPTIONS:GENERATE', async () => {
    await captionsStore.generateCaptions(preset, captionStyleDefinition.highlightColor);
  });
};

onMounted(() => {
  // We don't want to keep legacy captions when you get into this component.
  legacyCaptionsStore.$reset();
});
</script>

<template>
  <div class="flex flex-col gap-4 p-0 pt-4 pb-0 2xl:pt-8 h-full sm:h-screen overflow-y-auto">
    <div class="relative h-full p-4 2xl:p-8">
      <Transition
        enter-from-class="opacity-0 translate-x-6" leave-to-class="opacity-0 -translate-x-6"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <template v-if="captionsStore.isGenerating">
          <div class="flex flex-col pt-2 items-center text-center h-full gap-2">
            <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
            <p>
              <strong>Making magic</strong>
            </p>
            <p class="my-2 font-light">
              This process might take up to 20 seconds depending on the length of your clip.
            </p>
            <div class="text-emerald-600 bg-emerald-500/5 dark:text-emerald-500 dark:bg-emerald-500/5 p-4 rounded-lg border border-current flex justify-center items-center gap-4 font-light text-sm">
              You can continue working on your project while we generate the captions.
            </div>
          </div>
        </template>
        <template v-else-if="!captionsStore.hasGeneratedCaptions">
          <div class="flex flex-col gap-2 absolute inset-0">
            <header class="hidden md:block px-4">
              <div class="flex gap-1 items-center">
                <h2 class="text-xl font-semibold leading-snug font-title -mt-[1px]">AI-Powered Captions</h2>
                <span class="-mt-0.5 top-2.5 left-2.5 rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background">
                  BETA
                </span>
              </div>
              <p class="font-light">
                Select a caption style below and customize it to your preferences after adding captions.
              </p>
            </header>
            <CaptionV2StyleSelector @select="onSelect" />
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col gap-4 absolute inset-0">
            <CaptionEditorTabs />
          </div>
        </template>
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
