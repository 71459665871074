<script setup lang="ts">
import { Switch } from '@/components/ui/switch'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { capitalize, ref } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'

const captionsStore = useCaptionsStore()
const historyStore = useHistoryStore()

const bleepCurseWords = ref('false');

const toggleEmojis = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_EMOJIS', () => {
    captionsStore.baseOptions.emojis = !captionsStore.baseOptions.emojis
  });
};
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-2 select-none mt-4 px-4">

    <div v-if="captionsStore.baseOptions.emojis" class="flex flex-col gap-2 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleEmojis">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Add emojis automatically</h3>
            <p class="text-sm text-muted-foreground font-light">Magically add emojis to captions. ✨</p>
          </div>
  
          <Switch v-model:checked="captionsStore.baseOptions.emojis" @click.stop />
        </header>
      </section>
    </div>

    <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
      <div class="flex flex-col gap-2 justify-between max-h-[300px] mt-1">
        <div class="flex flex-row gap-2">
          <h3 class="font-semibold text-lg">Special Effects ✨</h3>
        </div>
        <p class="text-sm text-muted-foreground font-light">Bleep curse words, add sound effects, animated emojis and more to your captions!</p>
      </div>

      <div class="my-1 h-px bg-surface-panel-border" />

      <div class="relative">

        <section class="flex flex-col gap-2 p-3 rounded-xl bg-surface-panel">
          <header class="flex items-start justify-between gap-2 cursor-pointer">
            <div class="flex flex-col gap-0">
              <h3 class="font-semibold text-lg">Bleep curse words</h3>
              <p class="text-sm text-muted-foreground font-light">Automatically detect curse words and emit a sound effect instead</p>
            </div>
          </header>

          <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
            <RadioToggleButton
              v-for="option in [{ label: 'Disabled', value: 'false' }, { label: 'Enabled', value: 'true' }]"
              :key="option.value"
              :value="option.value"
              v-model="bleepCurseWords"
              class="flex-col relative font-light hover:bg-zinc-200 data-[state=active]:font-semibold data-[state=active]:bg-primary data-[state=active]:text-white !border-transparent bg-transparent shrink-0 flex-1"
            >
              <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
              <span class="font-semibold invisible">{{ capitalize(option.label) }}</span>
            </RadioToggleButton>
          </footer>
        </section>

        <div class="mt-4 flex flex-col gap-2 p-2">
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
        </div>

        <div class="top-0 backdrop-blur-sm rounded-xl absolute w-full h-full flex items-center justify-center">
          <span class="rotate-6 rounded font-semibold text-xl uppercase px-2 py-1 bg-green-700 text-background -mt-0.5 cursor-default user-select-none">
            Coming soon!
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">

</style>
