<script setup lang="ts">
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import '../index.css'
import { useIsMobile } from '@/Hooks/useIsMobile'
import EditorLayoutMobile from '@/areas/editor/pages/EditorLayoutMobile.vue'
import EditorLayoutDesktop from '@/areas/editor/pages/EditorLayoutDesktop.vue'
import { useKeyCombination } from '@/lib/keybindings';
import { useToast } from '@/Hooks/useToast';

// Ensure history store is initialized before any other store
useHistoryStore();

const isMobile = useIsMobile();

const { showToast } = useToast();
useKeyCombination('save', () => {
  showToast({
    type: 'toast',
    title: '💾 Your project is saved automatically',
    subtitle: 'We save your project in the background every couple of seconds.',
    timeout: 5_000,
  });
}, { prevent: true });

const historyStore = useHistoryStore(); 
onMounted(() => historyStore.$reset());
</script>

<template>
  <template v-if="isMobile">
    <EditorLayoutMobile>
      <template #router-view>
        <slot name="router-view" />
      </template>
    </EditorLayoutMobile>
  </template>
  <template v-else>
    <EditorLayoutDesktop>
      <template #router-view>
        <slot name="router-view" />
      </template>
    </EditorLayoutDesktop>
  </template>
</template>

<style scoped lang="scss">

</style>
