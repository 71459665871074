<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { editorRouteNames } from '@/areas/editor/routeNames'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { ref, onMounted, computed, watch } from 'vue'
import { getApiCustomStickers, postApiCustomStickers } from '@/apis/streamladder-api/custom-stickers/custom-stickers'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import Tile from '@/components/Tile.vue'
import type { CustomSticker as ApiCustomSticker } from '@/apis/streamladder-api/model'
import { Skeleton } from '@/components/ui/skeleton'
import AppImage from '@/areas/editor/components/AppImage.vue'
import uploadService from '@/services/uploadService'
import { mbToBytes, formatFileSize } from '@/helpers/fileSize'
import { round } from 'lodash-es'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useScreen } from '@/Hooks/useScreen'
import { Input } from '@/components/ui/input'
import EventBus from '@/eventBus'
import toastEvents from '@/events/toastEvents'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useToast } from '@/Hooks/useToast'
import { useRouter } from 'vue-router'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Progress } from '@/components/ui/progress'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import logging from '@/logging'
import { setLastUsedCustomStickerFileUrl } from '@/areas/editor/views/elements/useElementsSettings'
import { lastUsedCustomStickerFirst } from '@/helpers/isNewOverlayElement'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useUserInfoStore } from '@/store/user/userInfo'

defineProps<{
  hideBackButton?: boolean
}>();

const stickers = ref<ApiCustomSticker[]>([])
const isFetching = ref(false)
async function fetchStickers() {
  isFetching.value = true
  stickers.value = await getApiCustomStickers()
  isFetching.value = false
}

const isDeleting = ref<string[]>([])

onMounted(async () => {
  await fetchStickers()
})

const stickersStore = useStickersStore()

async function loadImageByUrl(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject()
    img.src = url
  })
}

const editorFocusStore = useEditorFocusStore()
const historyStore = useHistoryStore()

async function addStickerToProject(sticker: ApiCustomSticker) {

  try {

    const image = await loadImageByUrl(sticker.fileUrl)
    const drawWidth = 0.8 * 1080
    const drawHeight = image.naturalHeight * (drawWidth / image.naturalWidth)

    const id = stickersStore.createCustomSticker({
      imageUrl: sticker.fileUrl,
      naturalHeight: image.naturalHeight,
      naturalWidth: image.naturalWidth,
      area: {
        x: 0.1,
        y: 0.1 + Math.random() * (0.9 - drawHeight / 1920),
        width: drawWidth / 1080,
        height: drawHeight / 1920,
      }
    })

    logging.trackEvent('Editor CustomSticker Added', {
      ImageUrl: sticker.fileUrl,
    })

    setLastUsedCustomStickerFileUrl(sticker.fileUrl)

    setTimeout(() => editorFocusStore.setFocus(FocusTypes.STICKER, id), 0)

    historyStore.transaction('STICKER:ADD')
  } catch (e) {
    console.error(e)
  }
}

const isProgressDialogOpen = ref(false)
const error = ref<string | null>()

async function uploadImageFile(event: HTMLInputElement) {

  const inputElement = event.target as HTMLInputElement
  const result = await tryUploadImageFile(inputElement.files?.item(0))

  if (result.error) {
    console.error(result.error)
    error.value = result.error
  } else {
    await fetchStickers()
  }

  inputElement.value = null
}

const { showToast } = useToast()

const router = useRouter()

const uploadPercentage = ref(0)
const maxFileSize = mbToBytes(5)
async function tryUploadImageFile(file: File | null) {

  if (!file) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'No file selected',
      subtitle: 'Please select a file to upload.',
      timeout: 10000,
    });
    return { error: null }
  }

  if (file.size > maxFileSize) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'File too large, please try another file',
      subtitle: `Max file size is ${formatFileSize(maxFileSize, 2)}.`,
      timeout: 10000,
    });
    return { error: null }
  }

  try {
    uploadPercentage.value = 0
    isProgressDialogOpen.value = true

    const result = await uploadService.getUploadCustomStickerSignedUrl()
    await uploadService.uploadFileS3(result.signedUrl, file, (progress) => {
      uploadPercentage.value = round(progress, 2)
    })

    await postApiCustomStickers({ fileUrl: result.resultUrl })

    await addStickerToProject(result.resultUrl)
    isProgressDialogOpen.value = false

    return { error: null }
  } catch (e) {
    console.error(e)
    EventBus.$emit(toastEvents.TOAST_ERROR, {
      title: "File upload failed",
      subtitle: "Please try again or contact support.",
      view: () => {
        router.push({
          name: dashboardRouteNames.support,
        })
      },
      viewTitle: 'Support',
    });
    return { error: null }
  }
}

const { currentSection } = useEditorStep()
const viewAll = () => {
  currentSection.value = editorRouteNames.uploads
};

watch(currentSection,async () => {
  await fetchStickers();
}, { immediate: true });

const is2Xl = useScreen('2xl')
const maxItems = computed(() => {
  return is2Xl.value ? 5 : 4;
});

const filteredStickers = computed(() => {
  return [...stickers.value]
    .sort(lastUsedCustomStickerFirst)
    .slice(0, maxItems.value);
})

const userInfoStore = useUserInfoStore();
</script>

<template>
  <div class="flex flex-row gap-2 justify-between items-center">
    <h2 class="text-base font-semibold leading-snug">Custom stickers</h2>
    <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
      View all
    </Button>
  </div>

  <Button as="label" class="relative gap-2 bg-transparent hover:bg-transparent w-full h-32 font-light text-current active:scale-90 transition-[transform,_border-color,_background-color] border border-dashed border-brand-state-hover-border rounded-lg flex flex-col justify-center items-center cursor-pointer hover:scale-[101%] group">
    <DynamicPlanButtonWithTooltip feature="stickers" class="absolute right-2 top-2" />
    <IconSaxDocumentUpload class="w-5 h-5 text-brand-state-hover-border dark:text-white group-hover:scale-110 group-hover:-translate-y-0.5 transition-transform" />
    <div class="flex flex-col items-center justify-center">
      <span>Upload a sticker</span>
      <span class="text-xs text-gray-500">Max 5 MB files are allowed</span>
      <span class="text-xs text-gray-500">Supported formats: .png, .jpeg, .gif</span>
    </div>
    <div class="absolute w-full h-full">
      <Input
        class="hidden" ref="input" id="custom-sticker-input"
        type="file" name="custom-sticker" accept="image/png, image/jpeg, image/gif"
        @input="uploadImageFile"
      />
    </div>
  </Button>

  <template v-if="isFetching">
    <div class="max-h-[100px] grid w-full gap-2 grid-cols-[repeat(auto-fill,_minmax(50px,_1fr))] lg:grid-cols-[repeat(auto-fill,_minmax(70px,_1fr))] overflow-hidden">
      <Skeleton v-for="i in 4" :key="i" class="aspect-square w-full border border-surface-panel-border" />
    </div>
  </template>
  <template v-else>
    <div class="max-h-[100px] grid w-full gap-2 grid-cols-[repeat(auto-fill,_minmax(50px,_1fr))] lg:grid-cols-[repeat(auto-fill,_minmax(70px,_1fr))] overflow-hidden">
      <div v-for="sticker in filteredStickers" :key="sticker.id">
        <Tile
          @click="() => addStickerToProject(sticker)"
          class="active:scale-90 transition-[transform,_border-color,_background-color] group"
          v-if="!isDeleting.includes(sticker.id)"
        >
          <DynamicPlanButtonWithTooltip feature="stickers" class="absolute right-2 top-2" />
          <div class="aspect-square">
            <AppImage class="h-full w-full cursor-pointer object-contain" :src="sticker.fileUrl" alt="Custom sticker preview" />
          </div>
        </Tile>
        <Skeleton v-else class="aspect-square w-full border border-surface-panel-border" />
      </div>
    </div>
  </template>

  <Dialog v-model:open="isProgressDialogOpen">
    <DialogContent>
      <div class="flex flex-col items-center justify-center gap-4">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold">Hold tight! ✨</h2>
        <p class="text-center text-lg font-light opacity-75">We're saving your custom sticker!</p>
        <Progress :model-value="uploadPercentage" />
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
