<script setup lang="ts">
import { ref, computed, reactive, onMounted } from 'vue'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '@/components/ui/select'
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import { useFontsStore } from '@/store/fonts'
import { ColorInput } from '@/components/colors'
import { Slider } from '@/components/ui/slider'
import { NumberField, NumberFieldContent, NumberFieldInput } from '@/components/ui/number-field'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { Switch } from '@/components/ui/switch'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'

const accordion = ref<string[]>(['text'])

const captionsStore = useCaptionsStore()
const preset = reactive<CaptionPreset>(captionsStore.baseCaptionPreset)

const historyStore = useHistoryStore()

const isLoadingFonts = ref(true);

const fontsStore = useFontsStore()
onMounted(async () => {
  await fontsStore.initialize()
  isLoadingFonts.value = false
});

const strokeWidthModel = computed({
  get() {
    return [preset.stroke.width];
  },
  set(value: number[]) {
    historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH', () => {
      preset.stroke.width = value[0];
    })
  }
})

const backgroundOpacityModel = computed({
  get() {
    return preset.background.opacity * 100;
  },
  set(value: number) {
    historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY', () => {
      preset.background.opacity = value / 100;
    })
  }
})

const backgroundOpacitySliderModel = computed({
  get() {
    return [backgroundOpacityModel.value];
  },
  set(value: number[]) {
    historyStore.transaction('CAPTIONS:CHANGE_BACKGROUND_OPACITY', () => {
      backgroundOpacityModel.value = value[0];
    });
  }
})

const toggleRotation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_ROTATION', () => {
    captionsStore.baseOptions.rotate = !captionsStore.baseOptions.rotate
  })
};

const toggleStripPunctuation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_STRIP_PUNCTUATION', () => {
    captionsStore.baseOptions.stripPunctuation = !captionsStore.baseOptions.stripPunctuation
  });
};
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-4 my-8">
    <div class="flex flex-col gap-2 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleRotation">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Random rotation</h3>
            <p class="text-sm text-muted-foreground font-light">Give each caption a slight and random rotation.</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.rotate" @click.stop />
        </header>
      </section>
    </div>

    <div class="flex flex-col gap-2 px-4">
      <section class="flex flex-col gap-2 p-3 rounded-xl border border-surface-panel-border bg-surface-panel">
        <header class="flex items-start justify-between gap-2 cursor-pointer" @click="toggleStripPunctuation">
          <div class="flex flex-col gap-0">
            <h3 class="font-semibold text-lg">Hide punctuation</h3>
            <p class="text-sm text-muted-foreground font-light">Remove all ".?!" from captions</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.stripPunctuation" @click.stop />
        </header>
      </section>
    </div>
  </div>

  <div class="my-1 h-px bg-surface-panel-border" />

  <Accordion collapsible v-model="accordion" class="w-full">

    <AccordionItem value="text" class="w-full">
      <AccordionTrigger class="px-4 pointer-events-none">
        <span class="text-base font-semibold">Style</span>
        <template #icon><div /></template>
      </AccordionTrigger>
      <AccordionContent class="px-4">
        <div v-if="isLoadingFonts" class="flex flex-col gap-2 py-2">
          Loading..
        </div>
        <Transition
          enter-from-class="opacity-0 -translate-y-4"
          leave-to-class="opacity-0 -translate-y-4"
          enter-active-class="motion-safe:transition-[transform,_opacity]"
          leave-active-class="motion-safe:transition-[transform,_opacity]"
        >
          <div v-if="!isLoadingFonts" class="flex flex-col gap-2 py-2">
            <Select v-model="preset.font.fontFamily">
              <SelectTrigger class="hover:bg-brand-state-hover-bg hover:border-brand-state-hover-border">
                <SelectValue placeholder="Choose a font" :style="{ fontFamily: preset.font.fontFamily }" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="font in fontsStore.fonts" :key="font.url" :value="font.label" :style="{ fontFamily: font.label }">
                  {{ font.label }}
                </SelectItem>
              </SelectContent>
            </Select>
            <ColorInput v-model="preset.font.color" class="shrink-0" />
          </div>
        </Transition>
      </AccordionContent>
    </AccordionItem>

    <section class="p-4" v-if="preset.stroke.editable">

      <header class="my-2">
        <span class="text-base font-semibold">Stroke</span>
      </header>

      <div class="flex flex-col gap-2 py-2">

        <ColorInput v-model="preset.stroke.color" />
        <div class="flex gap-2">
          <Slider v-model="strokeWidthModel" :min="0" :max="10" :step="1" />
          <NumberField :min="0" :max="10" v-model="preset.stroke.width" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pr-5" />
              <span class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 right-0 absolute">px</span>
            </NumberFieldContent>
          </NumberField>
        </div>

      </div>
    </section>

    <div class="my-1 h-px bg-surface-panel-border" />

    <section class="p-4" v-if="preset.background.editable">

      <header class="my-2">
        <span class="text-base font-semibold">Background</span>
      </header>

      <div class="flex flex-col gap-2 py-2">
        <ColorInput v-model="preset.background.color" />
        <div class="flex gap-2">
          <Slider v-model="backgroundOpacitySliderModel" :min="0" :max="100" :step="1" />
          <NumberField :min="0" :max="100" v-model="backgroundOpacityModel" class="w-20">
            <NumberFieldContent>
              <NumberFieldInput class="pr-5" />
              <span class="text-muted-foreground p-3 top-1/2 -translate-y-1/2 right-0 absolute">%</span>
            </NumberFieldContent>
          </NumberField>
        </div>
      </div>
    </section>
  </Accordion>
</template>

<style scoped lang="scss">

</style>
