<script setup lang="ts">
import { hexToHsb, hsbToHex } from '@/components/colors/helpers'
import { throttle, clamp } from 'lodash-es'

const hexColor = defineModel<string>('hex', { required: true });

const { h, s, b } = hexToHsb(hexColor.value);
const hue = ref(h);
const saturation = ref(s);
const brightness = ref(b);

const isDragging = ref(false);

const grid = ref<HTMLElement | null>(null);
const updateSaturationBrightness = (event: MouseEvent) => {

  if (!isDragging.value) {
    return;
  }

  if (grid.value instanceof HTMLElement) {
    const rect = grid.value.getBoundingClientRect();
    const x = clamp(event.clientX - rect.left, 0, rect.width);
    const y = clamp(event.clientY - rect.top, 0, rect.height);

    saturation.value = Math.round((x / rect.width) * 100);
    brightness.value = Math.round(((rect.height - y) / rect.height) * 100);
  }
};

watch([hue, saturation, brightness], throttle(([h, s, b]) => {
  hexColor.value = hsbToHex(h, s, b);
}, 100));

function onMouseDown(event: MouseEvent) {
  isDragging.value = true;
  document.getElementById('app')?.classList.add('pointer-events-none', 'select-none')
  window.addEventListener('mouseup', onMouseUp);
  window.addEventListener('mousemove', updateSaturationBrightness);
  updateSaturationBrightness(event);
}

function onMouseUp() {
  isDragging.value = false;
  document.getElementById('app')?.classList.remove('pointer-events-none', 'select-none')
  window.removeEventListener('mouseup', onMouseUp);
  window.removeEventListener('mousemove', updateSaturationBrightness);
}
</script>

<template>
  <div class="w-full pb-[100%] h-0 relative">
    <div ref="grid"
      class="absolute inset-0 rounded-lg overflow-hidden sb-grid border border-input"
      @mousedown="onMouseDown"
      :style="{ backgroundColor: `hsl(${hue}, 100%, 50%)` }"
    />
    <div
      class="absolute w-6 h-6 shadow border-2 border-white rounded-full pointer-events-none -translate-x-1/2 -translate-y-1/2"
      :style="{ left: saturation + '%', top: 100 - brightness + '%', backgroundColor: hexColor }"
    />
  </div>

  <div class="relative h-6 flex items-center mt-4" :data-hue="hue">
    <div class="absolute inset-0 h-6 w-full rounded-md hue-slider">
      <div
        class="absolute shadow border-2 border-white rounded-md w-3 h-6 box-content -translate-x-1/2 -translate-y-1/2 top-1/2"
        :style="{ left: ((hue / 359) * 100) + '%', background: `hsl(${hue}, 100%, 50%)` }"
      />
    </div>
    <input type="range" :min="0" :max="359" v-model="hue" class="opacity-0 w-full h-6 cursor-pointer" />
  </div>
</template>

<style scoped>
.sb-grid {
    cursor: crosshair;
    background-image:
      linear-gradient(0deg, hsl(0, 0%, 0%), transparent),
      linear-gradient(90deg, hsl(0, 0%, 100%), transparent);
}

.hue-slider {
  background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000)
}
</style>
