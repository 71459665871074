import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { startupVideo, startupWithDefaultLayout } from '@/areas/editor/startup/generalStartupMethods'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { getApiKickClipsClipId } from '@/apis/streamladder-api/kick-clips/kick-clips';
import type { ClipDto } from '../@type/ClipDto';
import { listenForClipDownload } from '@/areas/editor/startup/listenForClipDownload';

export async function startupFromKickClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }

    const response = await getApiKickClipsClipId(clipId) as unknown as ClipDto
    config.signal?.throwIfAborted()
    return { error: null, clip: { ...response, id: response.id ?? clipId } }
  } catch (error) {
    return handleStartupError(error)
  }
}

export async function startupFromKickClip(clip: ClipDto, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title ?? 'Kick clip'
  editorClipInfoStore.viewCount = clip.viewCount
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.thumbnailUrl = clip.thumbnailUrl
  editorClipInfoStore.source = 'kick-clip'
  editorClipInfoStore.languageCode = clip.languageCode?.toLowerCase() ?? 'en_us'
  editorClipInfoStore.isLocalFile = false

  config.signal?.throwIfAborted()

  editorClipInfoStore.isLoadingClip = true

  const response = await tryDownloadClip(clip, config)
  config.signal?.throwIfAborted()

  if ('error' in response) {
    if (response.error) {
      editorClipInfoStore.loadingState = {
        state: 'error',
        title: 'Could not import clip 🚫',
        description: 'Try another clip or check back later!'
      };
    }
    return {
      error: response.error
    };
  } else {

    editorClipInfoStore.mp4Url = response.mp4Url;
    editorClipInfoStore.title = response.title || 'Kick clip'

    try {
      await startupVideo(config)
      config.signal?.throwIfAborted()

      await startupWithDefaultLayout(config)
      config.signal?.throwIfAborted()

      editorClipInfoStore.isLoadingClip = false
      editorClipInfoStore.loadingState = null

      return { error: null }
    } catch (error) {
      return handleStartupError(error)
    }
  }
}

async function tryDownloadClip(clip: ClipDto, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()

  // If we get an empty response from a Kick clip, we have to listen to the pusher client to retrieve the info needed.
  if (clip.mp4Url === '') {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Importing Kick Clip. This may take a few minutes...'
    }

    try {
      return await listenForClipDownload(clip, config)
    } catch (e) {
      return { error: e?.toString() ?? null }
    }
  } else {
    return clip
  }
}
