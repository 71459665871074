<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { syncRefs, useIntervalFn } from '@vueuse/core'
import { reactiveTransform } from '@vueuse/motion'
import { computed, ref, watch } from 'vue'
import { useDrag } from '@vueuse/gesture'
import { clamp } from 'lodash-es'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useProjectStore } from '@/areas/editor/store/useProjectStore';

const props = defineProps<{
  id: string
  startMs: number
  endMs: number
  minStartMs: number
  maxEndMs: number
  maxDurationMs?: number
  minDurationMs?: number
  active?: boolean
  height?: number
  seekBehavior?: 'seek' | 'none'
  enableTooltip?: boolean
}>()

const emit = defineEmits<{
  resizeStart: [startMs: number, endMs: number]
  dragStart: [startMs: number, endMs: number]
  dragging: [startMs: number, endMs: number]
  dragStop: [startMs: number, endMs: number]
  resize: [startMs: number, endMs: number]
  resizeStop: [startMs: number, endMs: number]
  activated: []
}>()

const {
  pxToMs,
  useMsToPx,
  dragging: timelineDragging,
  triggerSeek: _triggerSeek,
  containerEl,
  msToPx,
  timelinePadding,
  enableSnap,
  zoomLevel,
} = useTimelineStore()

const triggerSeek = (ms: number) => {
  if (props.seekBehavior === 'seek') _triggerSeek(ms)
}

const minWidth = computed(() => minDurationPx.value ?? 5);

const active = ref(props.active)
syncRefs(
  computed(() => props.active),
  active
)

const bodyEl = ref<HTMLElement>()

const _minStartPx = useMsToPx(
  computed(() => props.minStartMs),
  true
)
const _maxEndPx = useMsToPx(
  computed(() => props.maxEndMs),
  true
)

// I don't do underscores :D
const maxDurationPx = useMsToPx(
  computed(() => props.maxDurationMs ?? 0),
  true
)

const minDurationPx = useMsToPx(
  computed(() => props.minDurationMs ?? 0),
  true
)

const _startPx = useMsToPx(
  computed(() => props.startMs),
  true
)

const _endPx = useMsToPx(
  computed(() => props.endMs),
  true
)

const _height = ref(props.height)
const _width = ref(Math.max(0, msToPx(props.endMs - props.startMs, true)))

const { state, transform } = reactiveTransform({
  x: _startPx.value || 0,
  y: 0,
})

watch([_startPx, _endPx], ([x, e]) => {
  state.x = x
  _width.value = Math.max(0, e - x)
}, { immediate: true })

const initialPos = ref({
  x: state.x,
  width: _width,
})

const getDragByMovement = (movement: number): { x; width } => {
  const x = clamp(initialPos.value.x + movement, _minStartPx.value, _maxEndPx.value - initialPos.value.width)
  const width = _width.value
  return { x, width }
}

const getResizeByMovement = (movement: number, type: 'hr' | 'hl'): { x; width } => {
  if (type === 'hr') {
    const width = clamp(initialPos.value?.width + movement, minWidth.value, _maxEndPx.value - initialPos.value.x)
    return { x: initialPos.value.x, width: width }
  } else {
    const x = clamp(
      initialPos.value.x + movement,
      _minStartPx.value,
      initialPos.value.x + initialPos.value.width - minWidth.value
    )
    const width = initialPos.value.width - (x - initialPos.value.x)
    return { x, width: width }
  }
}

const dragType = ref<'body' | 'hl' | 'hr'>()

const direction = -1
const lastX = 0
const distance = 5

const { isActive } = useIntervalFn(() => {

    containerEl.value?.scrollBy({
      left: direction * distance,
    });

    if (dragType.value === 'body') {

      initialPos.value = {
        x: initialPos.value.x + direction * distance,
        width: initialPos.value.width,
      };

      const clampedMovement = getDragByMovement(lastX);
      state.x = clampedMovement.x;
      emit('dragging', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true));

    } else {

      if (dragType.value === 'hl') {
        initialPos.value = {
          x: initialPos.value.x + direction * distance,
          width: initialPos.value.width - direction * distance,
        };
      }

      if (dragType.value === 'hr') {
        initialPos.value = {
          x: initialPos.value.x,
          width: initialPos.value.width + direction * distance,
        };
      }

      const clampedMovement = getResizeByMovement(lastX, dragType.value);
      state.x = clampedMovement.x;
      _width.value = clampedMovement.width;
      emit('resizing', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true));
    }
  },
  50,
  {
    immediate: false,
  }
);

const snaplineX = ref<number | null>(null);

const stickersStore = useStickersStore();
const segmentsStore = useSegmentsStore();
const effectsStore = useEffectsStore();
const editorCaptionStore = useEditorCaptionsStore();
const captionsStore = useCaptionsStore();
const projectStore = useProjectStore();

const otherElementsLeftAndRights = ref([]);

const getOtherElementsLeftAndRights = () => {

  const positions = [];

  for (const sticker of stickersStore.entities.filter((s) => s.id !== props.id)) {
    positions.push({
      id: sticker.id,
      left: msToPx(sticker.startMs) - timelinePadding,
      right: msToPx(sticker.endMs) - timelinePadding,
    });
  }

  const currentTargetIsNotASegment = !segmentsStore.entities.some(s => s.id === props.id);
  if (currentTargetIsNotASegment) {
    for (const segment of segmentsStore.entities.filter((s) => s.id !== props.id)) {
      positions.push({
        id: segment.id,
        left: msToPx(segment.startMs) - timelinePadding,
        right: msToPx(segment.endMs) - timelinePadding,
      });
    }
  }

  for (const effect of effectsStore.entities.filter((e) => e.id !== props.id)) {
    if ('startMs' in effect && 'endMs' in effect) {
      positions.push({
        id: effect.id,
        left: msToPx(effect.startMs) - timelinePadding,
        right: msToPx(effect.endMs) - timelinePadding,
      });
    }
  }

  if (projectStore.useLegacyCaptions) {

    const allCaptionIds = editorCaptionStore.captions.flatMap(caption => caption.words).map(w => w.id);
    const currentTargetIsNotACaption = !allCaptionIds.includes(props.id);

    if (currentTargetIsNotACaption) {
      for (const caption of editorCaptionStore.captions.filter((c) => c.id !== props.id)) {
        if (caption.id !== props.id) {
          for (const word of caption.words) {
            if (word.id !== props.id) {
              positions.push({
                id: word.id,
                left: msToPx(word.start) - timelinePadding,
                right: msToPx(word.end) - timelinePadding,
              });
            }
          }
        }
      }
    }
  } else {
    const words = captionsStore.getAllWordIds();
    if (!words.includes(props.id)) {
      for (const caption of captionsStore.entities.filter((c) => c.id !== props.id)) {
        for (const word of caption.words) {
          if (word.id !== props.id) {
            positions.push({
              id: word.id,
              left: msToPx(word.start) - timelinePadding,
              right: msToPx(word.end) - timelinePadding,
            });
          }
        }
      }
    }
  }

  return positions;
};

const SNAP_DISTANCE = 8;

const handleResizeLeft = (currentLeft: number, currentRight: number, otherElement) => {

  let snappedX = currentLeft;
  let snappedWidth = currentRight - currentLeft;
  let hasSnapped = false;

  if (Math.abs(currentLeft - otherElement.right) < SNAP_DISTANCE) {
    // The left side of the current element is close to the right side of another element.
    snappedWidth = initialPos.value.width - (otherElement.right - initialPos.value.x);
    snappedX = otherElement.right;
    snaplineX.value = snappedX;
    hasSnapped = true;
  } else if (Math.abs(currentLeft - otherElement.left) < SNAP_DISTANCE) {
    // The left side of the current element is close to the left side of another element.
    snappedWidth = initialPos.value.width - (otherElement.left - initialPos.value.x);
    snappedX = otherElement.left;
    snaplineX.value = snappedX;
    hasSnapped = true;
  } else {
    snaplineX.value = null;
  }

  return {
    x: snappedX,
    width: snappedWidth,
    hasSnapped: hasSnapped,
  };
};

const handleResizeRight = (currentLeft: number, currentRight: number, otherElement) => {

  let snappedWidth = currentRight - currentLeft;
  let hasSnapped = false;

  if (Math.abs(currentRight - otherElement.left) < SNAP_DISTANCE) {
    // The right side of the current element is close to the left side of another element.
    snappedWidth = otherElement.left - currentLeft;
    snaplineX.value = otherElement.left;
    hasSnapped = true;
  } else if (Math.abs(currentRight - otherElement.right) < SNAP_DISTANCE) {
    // The right side of the current element is close to the right side of another element.
    snappedWidth = otherElement.right - currentLeft;
    snaplineX.value = otherElement.right;
    hasSnapped = true;
  } else {
    snaplineX.value = null;
  }

  return {
    x: currentLeft,
    width: snappedWidth,
    hasSnapped: hasSnapped,
  };
};

const handleDrag = (currentLeft: number, currentRight: number, otherElement) => {

  let snappedX = currentLeft;
  let hasSnapped = false;

  if (Math.abs(currentLeft - otherElement.right) < SNAP_DISTANCE) {
    // The left side of the current element is close to the right side of another element.
    snappedX = otherElement.right;
    snaplineX.value = otherElement.right;
    hasSnapped = true;
  } else if (Math.abs(currentRight - otherElement.left) < SNAP_DISTANCE) {
    // The right side of the current element is close to the left side of another element.
    snappedX = otherElement.left - (currentRight - currentLeft);
    snaplineX.value = otherElement.left;
    hasSnapped = true;
  } else if (Math.abs(currentLeft - otherElement.left) < SNAP_DISTANCE) {
    // The left side of the current element is close to the left side of another element.
    snappedX = otherElement.left;
    snaplineX.value = otherElement.left;
    hasSnapped = true;
  } else if (Math.abs(currentRight - otherElement.right) < SNAP_DISTANCE) {
    // The right side of the current element is close to the right side of another element.
    snappedX = otherElement.right - (currentRight - currentLeft);
    snaplineX.value = otherElement.right;
    hasSnapped = true;
  } else {
    snaplineX.value = null;
  }

  return {
    x: snappedX,
    width: currentRight - currentLeft,
    hasSnapped: hasSnapped,
  };
};

const snapToNearbyElements = (currentLeft, currentRight) : { snappedX: number, snappedWidth: number } => {

  if (!enableSnap.value) {
    return { snappedX: currentLeft, snappedWidth: currentRight - currentLeft };
  }

  const otherElements = otherElementsLeftAndRights.value;

  let snappedX = currentLeft;
  let snappedWidth = currentRight - currentLeft;

  for (const otherElement of otherElements) {
    if (dragType.value === 'hl') {
      const result = handleResizeLeft(currentLeft, currentRight, otherElement);
      snappedX = result.x;
      snappedWidth = result.width;
      if (result.hasSnapped) break;
    } else if (dragType.value === 'hr') {
      const result = handleResizeRight(currentLeft, currentRight, otherElement);
      snappedX = result.x;
      snappedWidth = result.width;
      if (result.hasSnapped) break;
    } else {
      const result = handleDrag(currentLeft, currentRight, otherElement);
      snappedX = result.x;
      snappedWidth = result.width;
      if (result.hasSnapped) break;
    }
  }

  return { snappedX, snappedWidth };
};

const onDrag = ({ movement: [xM], dragging, first, last, xy: [x], _dragTarget, tap, event }) => {

  if (tap) {
    emit('activated');
    snaplineX.value = null;
    return;
  }

  let clampedAndSnappedX = 0;

  event.preventDefault()

  if (first && dragging) {
    const target = _dragTarget || (event.target as HTMLElement)
    dragType.value = ((target?.closest('[data-drag-type]') as HTMLElement)?.dataset.dragType as any) || 'body'
    timelineDragging.value = { type: 'node' }
    initialPos.value = {
      x: state.x,
      width: _width.value,
    };
  }

  const inBounds = isActive.value

  if (dragType.value == 'body') {

    const clampedMovement = getDragByMovement(xM);

    if (!inBounds) {
      state.x = snapToNearbyElements(clampedMovement.x, clampedMovement.x + clampedMovement.width, initialPos).snappedX;
      clampedAndSnappedX = state.x;
    }

    if (first) {
      otherElementsLeftAndRights.value = getOtherElementsLeftAndRights();
      emit('dragStart', props.startMs, props.endMs);
    }

    if (dragging){
      emit('dragging', pxToMs(clampedAndSnappedX, true), pxToMs(clampedAndSnappedX + clampedMovement.width, true))
    }

    if (last) {
      otherElementsLeftAndRights.value = getOtherElementsLeftAndRights();
      snaplineX.value = null;
      emit('dragStop', pxToMs(clampedAndSnappedX, true), pxToMs(clampedAndSnappedX + clampedMovement.width, true))
    }
  } else {

    const clampedMovement = getResizeByMovement(xM, dragType.value!);
    const { snappedX, snappedWidth } = snapToNearbyElements(clampedMovement.x, clampedMovement.x + clampedMovement.width, initialPos, true);

    if (!inBounds) {

      // Update `state.x` based on snapped position
      state.x = snappedX;

      // Update `_width.value` to reflect the snapped width when resizing
      _width.value = snappedWidth;
    }

    if (first) {
      otherElementsLeftAndRights.value = getOtherElementsLeftAndRights();
      emit('resizeStart', props.startMs, props.endMs)
    }

    if (dragging) {
      emit('resize', pxToMs(snappedX, true), pxToMs(snappedX + snappedWidth, true))
    }

    if (last) {

      otherElementsLeftAndRights.value = getOtherElementsLeftAndRights();

      if (dragType.value === 'hl') {
        triggerSeek(pxToMs(snappedX, true))
      }

      snaplineX.value = null;

      emit('resizeStop', pxToMs(snappedX, true), pxToMs(snappedX + snappedWidth, true))
    }
  }

  if (last) {
    timelineDragging.value = undefined
    dragType.value = undefined
    snaplineX.value = null
  }
}

useDrag(onDrag, {
  domTarget: bodyEl,
  eventOptions: {
    passive: false,
    capture: true,
  },
});

const handleSize = 100;
const useHandles = computed(() => zoomLevel.value < 0.75 ? false : _width.value > handleSize);
</script>

<template>
  <div
    ref="bodyEl"
    data-track-node
    :style="{ transform, height: `${_height}px`, width: `${_width}px` }"
    :data-state="active ? 'active' : 'inactive'"
    class="absolute top-0 select-none group/node"
    @click.stop
  >
    <teleport to="#timeline-container" v-if="containerEl">
      <Transition
        appear
        enter-active-class="transition-[_opacity] duration-50"
        leave-active-class="transition-[_opacity] duration-50"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="snaplineX"
          :style="{ left: `${snaplineX + timelinePadding}px` }"
          class="absolute -translate-x-1/2 top-0 h-full w-[2px] bg-company-primary-50 z-20"
        />
      </Transition>
    </teleport>
    <Tooltip disableHoverableContent :delay-duration="500" :disabled="!enableTooltip">
      <TooltipTrigger class="relative h-full w-full">
        <slot class="pointer-events-none" />
        <div class="absolute inset-0 opacity-0 group-hover/node:opacity-100 group-data-[state=active]/node:opacity-100 transition-opacity">
          <div
            data-drag-type="hr"
            class="absolute cursor-w-resize h-full"
            :class="{
              '!-right-4 pr-4': useHandles,
              '!pr-0 !-right-[2px] w-[4px] hover:bg-company-primary-100 opacity-75 hover:scale-y-[185%] transition-transform': !useHandles,
            }"
          >
            <slot v-if="useHandles" name="handles">
              <div class="absolute flex h-full flex-row gap-0.5 px-1 py-0">
                <div class="h-full w-1 rounded bg-gray-700"></div>
                <div class="h-full w-1 rounded bg-gray-700"></div>
              </div>
            </slot>
          </div>
          <div
            data-drag-type="hl"
            class="absolute h-full cursor-e-resize"
            :class="{
              '!-left-4 pl-4': useHandles,
              '!pl-0 !-left-[2px] w-[4px] hover:bg-company-primary-100 opacity-75 hover:scale-y-[185%] transition-transform': !useHandles,
            }"
          >
            <slot v-if="useHandles" name="handles">
              <div class="absolute flex h-full flex-row0 gap-0.5 px-1 py-0">
                <div class="h-full w-1 rounded bg-gray-700"></div>
                <div class="h-full w-1 rounded bg-gray-700"></div>
              </div>
            </slot>
          </div>
        </div>
      </TooltipTrigger>
      <TooltipContent side="top" class="py-1 px-1.5">
        <slot name="tooltip-content" />
      </TooltipContent>
    </Tooltip>
  </div>
</template>

<style scoped>

</style>
