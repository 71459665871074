<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { FocusTypes, useEditorFocusStore } from "@/store/editor/editorFocus";
import { computed, type ComputedRef, ref, watch } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { createZoomAt } from "@/areas/editor/store/functions/createZoomAt";
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import type { Segment } from '@/areas/editor/@type/Project'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

const videoStore = useVideoStore()
const editorFocusStore = useEditorFocusStore()

const segmentsStore = useSegmentsStore()
const zoomSegments = segmentsStore.whereIsZoom() as ComputedRef<Segment[]>

const effectsStore = useEffectsStore()

function addZoom() {
  createZoomAt(videoStore.currentTimeMs);
}

function editZoom() {
  if (currentZoomSegment.value?.id) {
    const effect = effectsStore.selectBySegmentId(currentZoomSegment.value.id);
    if (effect.value) {
      editorFocusStore.setFocus(FocusTypes.ZOOM, effect.value.id);
    }
  }
}

const scrollContainer = ref<HTMLDivElement | null>(null)
const isMoving = ref(false)

const userInfoStore = useUserInfoStore();

const currentZoomSegment = computed(() => {
  return zoomSegments.value.find(effect => effect.startMs <= videoStore.currentTimeMs && effect.endMs >= videoStore.currentTimeMs);
})

const { currentSection } = useEditorStep();

watch(() => editorFocusStore.focus, () => {
  if (editorFocusStore.focus?.type === FocusTypes.ZOOM) {
    currentSection.value = editorRouteNames.zooms;
  }
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-col gap-4 min-h-0" :class="isMoving ? 'overflow-hidden' : 'overflow-y-auto'" ref="scrollContainer">
      <div class="flex flex-row gap-2 justify-between max-h-[300px] items-center mt-1 w-full">
        <div class="flex flex-row gap-2 justify-between w-full">
          <div class="flex flex-row gap-2">
            <h2 class="text-base font-semibold leading-snug">Zoom effects 🔍</h2>
          </div>
          <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
        </div>
      </div>

      <template v-if="currentZoomSegment">
        <div class="flex flex-col gap-3 items-center justify-center border p-4 rounded-md">
          <h2 class="text-base font-light leading-snug text-center">
            Edit the position and magnification of the current Zoom effect
          </h2>
          <Button size="lg" @click="editZoom" class="shrink-0 max-w-[220px] hover:scale-105 transition-transform">
            Edit Zoom Effect
            <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none h-4 w-4" small />
          </Button>
        </div>
      </template>

      <template v-else>
        <div class="flex flex-col gap-3 items-center justify-center border p-4 rounded-md">
          <h2 class="text-base font-light leading-snug text-center">
            Highlight key moments with a timed custom Zoom effect
          </h2>
          <Button size="lg" @click="addZoom" class="shrink-0 max-w-[220px] hover:scale-105 transition-transform">
            Add Zoom Effect
            <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none h-4 w-4" small />
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
