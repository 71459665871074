<script setup lang="ts">
import { computed, watch, ref, onMounted, onUnmounted, defineAsyncComponent, type DeepReadonly } from 'vue';
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useConfirmDialog, useEventListener } from '@vueuse/core'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import CaptionEditorLine from '@/areas/editor/views/captions/CaptionEditorLine.vue'
import EditIcon from '@/components/Icons/EditIcon.vue'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import ResetIcon from '@/components/Icons/ResetIcon.vue'
import { Button } from '@/components/ui/button'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import { AlertDialog, AlertDialogContent, AlertDialogFooter, AlertDialogTitle, AlertDialogDescription } from '@/components/ui/alert-dialog'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import { useCurrentCaption } from '@/areas/editor/views/captions/useCurrentCaption'
import TrashcanIcon from '@/components/Icons/TrashcanIcon.vue'
import type { StoredWord, CaptionsDocument } from '@/components/Captions/captionTypes';
import { sumBy, clamp } from 'lodash-es'
import EmojiIcon from '@/components/Icons/Normalized/EmojiIcon.vue'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import logging from '@/logging'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'
import { useTheme } from '@/Hooks/useTheme'
import { isInputTarget } from '@/areas/editor/timeline/helpers'
import { useCaptionMethods } from '@/areas/editor/views/captions/useCaptionMethods'
import { useToast } from '@/Hooks/useToast'
import ToastEvents from '@/events/toastEvents'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import CaptionColorSelector from '@/areas/editor/views/captions/CaptionColorSelector.vue'
import { useProjectStore } from '@/areas/editor/store/useProjectStore';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))

const editorCaptionsStore = useEditorCaptionsStore()

const projectsStore = useProjectStore()

function unfreeze<T extends Record<string, unknown>>(obj: DeepReadonly<T>): T {
  return JSON.parse(JSON.stringify(obj))
}

const historyStore = useHistoryStore()
const { generateCaptionsAsync } = useGenerateCaptions()
const { reveal, confirm, cancel, isRevealed } = useConfirmDialog()
const resetCaptions = async () => {
  const { isCanceled } = await reveal()
  if (isCanceled) {
    return
  }

  const project = projectsStore.project;
  const document = project?.captions.document;
  if (document) {
    historyStore.transaction('CAPTIONS:RESET', () => {
      editorCaptionsStore.captionsDocument = unfreeze<CaptionsDocument>(document)
    })
  } else {
    historyStore.transaction('CAPTIONS:REGENERATE', generateCaptionsAsync)
  }
}
const segmentsStore = useSegmentsStore()
const filteredCaptions = computed(() => {
  return editorCaptionsStore.captions.map((caption) => {
    const segment = segmentsStore.entities.find((s) => {
      return caption.end >= s.startMs && caption.start <= s.endMs
    })

    return {
      ...caption,
      segmentId: segment?.id,
    }
  })
})
const videoStore = useVideoStore()
const { showToast } = useToast()
const { findCaptionAt, findWordAtCurrentTime, findLastCaptionBefore, findFirstCaptionAfter }
  = useCaptionMethods()
async function addCaption() {

  const minDurationMs = 100
  const maxDurationMs = 1000

  if (findCaptionAt(videoStore.currentTimeMs)) {
    return showToast({
      type: ToastEvents.TOAST,
      title: 'Already a caption at this position',
      subtitle: 'Please select a different position',
    })
  }

  const previousCaption = findLastCaptionBefore(videoStore.currentTimeMs)
  const nextCaption = findFirstCaptionAfter(videoStore.currentTimeMs)

  const minStartMs = previousCaption ? previousCaption.end : 0
  const maxEndMs = nextCaption ? nextCaption.start : videoStore.durationMs
  const duration = Math.min(maxDurationMs, maxEndMs - minStartMs)

  if (duration < minDurationMs) {
    return showToast({
      type: ToastEvents.TOAST,
      title: 'Not enough space for a caption',
      subtitle: 'Please select a different position',
    })
  }
  const end = Math.min(maxEndMs, videoStore.currentTimeMs + duration)
  const start = Math.max(minStartMs, end - duration)
  
  historyStore.transaction('CAPTIONS:ADD', () => {

    const caption = editorCaptionsStore.createCaption(start, end)
    editorCaptionsStore.groupedCaptions.push(caption)
    editorCaptionsStore.groupedCaptions.sort((a, b) => a.start - b.start)
  
    logging.trackEvent('Caption added', {})
  
    setTimeout(() => {
      const focusStore = useEditorFocusStore()
      focusStore.setFocus('caption', caption.id)
      focusOnCurrentWord()
    }, 0)
  })
}

const { currentCaptionId, currentWordId, currentWord } = useCurrentCaption()
const focusedElement = ref<HTMLElement | null>(null)
function focusOnCurrentWord() {

  const firstCaption = filteredCaptions.value[0]
  if (!firstCaption) return

  const currentWord = findWordAtCurrentTime() ?? firstCaption.words[0]
  const element = document.getElementById('word-' + currentWord.id)
  if (element instanceof HTMLElement) {
    element.focus()
    element.click()
  }
}
const popoverPosition = ref<{ top: string; left: string } | null>(null)
function updateBoundingRect() {
  if (focusedElement.value) {
    const rect = focusedElement.value.getBoundingClientRect()
    popoverPosition.value = {
      top: rect.top + rect.height + window.scrollY + 'px',
      left: rect.left + 0.5 * rect.width + window.scrollX + 'px',
    }
  }
}
watch(focusedElement, () => updateBoundingRect())
const popover = ref<HTMLElement | null>(null)
const popoverOpen = ref(false)
function closePopover(e: MouseEvent | TouchEvent) {
  if (e.target instanceof HTMLElement && popover.value && popoverOpen.value && !popover.value.contains(e.target) && !e.target.id.startsWith('word-')) {
    popoverOpen.value = false
  }
}
const container = ref<HTMLElement>()
onMounted(() => {
  updateBoundingRect()
  window.addEventListener('resize', updateBoundingRect)
  window.addEventListener('scroll', updateBoundingRect)
  window.visualViewport?.addEventListener('resize', updateBoundingRect)
  container.value?.addEventListener('scroll', updateBoundingRect)

  window.addEventListener('click', closePopover)
})
onUnmounted(() => {
  window.removeEventListener('resize', updateBoundingRect)
  window.removeEventListener('scroll', updateBoundingRect)
  window.visualViewport?.removeEventListener('resize', updateBoundingRect)
  container.value?.removeEventListener('scroll', updateBoundingRect)

  window.removeEventListener('click', closePopover)
})
function findWord(element: Node | null | undefined, offset: number) {

  if (!element) return null

  element = element instanceof HTMLElement ? element : element.parentElement
  if (!(element instanceof HTMLElement)) return null
  const target = element.closest('[data-caption-id]')
  if (!target || !(target instanceof HTMLElement)) return null
  const captionId = target.dataset.captionId
  if (!captionId) return null

  const captionIndex = editorCaptionsStore.captions.findIndex((c) => c.id === captionId)
  const caption = editorCaptionsStore.captions[captionIndex]
  if (!caption) return null

  if (target.dataset.emoji) {
    const wordIndex = caption.words.length - 1
    return { captionIndex: captionIndex, wordIndex: wordIndex, offset: caption.words[wordIndex].text.length }
  } else if (target.dataset.wordIndex) {
    const wordIndex = Number(target.dataset.wordIndex)
    return { captionIndex: captionIndex, wordIndex: wordIndex, offset: offset }
  } else {
    return null
  }
}
function blur() {
  focusedElement.value?.blur()
  popoverOpen.value = false
}
async function onKeyPress(e: KeyboardEvent) {
  switch (e.key) {
    case 'Escape':
      return blur()
    case 'Delete':
    case 'Backspace':
      if (window.getSelection() && !isInputTarget(e)) {
        e.preventDefault()
        return deleteWordOrSelection()
      }
      return
    default:
      return
  }
}
useEventListener('keydown', onKeyPress)
function parseSelection(selection: Selection | null) {

  if (!selection) {
    return null
  }
  const anchor = findWord(selection.anchorNode, selection.anchorOffset)
  const focus = findWord(selection.focusNode, selection.focusOffset)

  if (!anchor || !focus) {
    return null
  }

  const anchorIndex = overallWordIndex(anchor.captionIndex, anchor.wordIndex)
  const focusIndex = overallWordIndex(focus.captionIndex, focus.wordIndex)

  if (anchorIndex > focusIndex) {
    return { from: focus, to: anchor }
  } else {
    return { from: anchor, to: focus }
  }
}
const currentSelection = ref<ReturnType<typeof parseSelection>>(null)
const currentWordColor = computed(() => currentWord.value?.color ?? null)
const colorOfSelection = computed(() => {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    const caption = editorCaptionsStore.captions[from.captionIndex]
    const word = caption?.words?.[from.wordIndex]
    const color = word?.color
    for (let i = from.captionIndex; i <= to.captionIndex; i++) {
      const caption = editorCaptionsStore.captions[i]
      if (caption?.words) {
        const end = i === to.captionIndex ? to.wordIndex : caption.words.length - 1
        for (let j = from.wordIndex; j <= end; j++) {
          const word = caption.words[j]
          if (word?.color !== color) {
            return undefined
          }
        }
      }
    }
    return color ?? null
  } else {
    return currentWordColor.value ?? null
  }
})
function onSelectStart() {
  window.addEventListener('mouseup', onSelectEnd)
}
async function onSelectEnd(selection: Selection | Event) {
  if (selection instanceof Selection) {
    currentSelection.value = parseSelection(selection)
  } else {
    currentSelection.value = parseSelection(window.getSelection())
  }
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    const word = editorCaptionsStore.captions[to.captionIndex].words[to.wordIndex]
    videoStore._currentTime = (word.start + 0.5 * (word.end - word.start)) / 1000 + 0.01
    videoStore.playing = false
    setTimeout(() => openPopoverForRange(from, to), 0)
  }
  window.removeEventListener('mouseup', onSelectEnd)
}
function openPopoverForRange(
  from: { captionIndex: number; wordIndex: number; offset: number; },
  to: { captionIndex: number; wordIndex: number; offset: number; }
) {
  const rects = getBoundingClientRectsInRange(from, to)
  if (rects.length > 0) {
    const bottom = Math.max(...rects.map((r) => r.bottom))
    const left = Math.min(...rects.map((r) => r.left))
    const right = Math.max(...rects.map((r) => r.right))

    const clampedTop = clamp(bottom + window.scrollY, 0, window.innerHeight - 150)
    const clampedLeft = clamp(0.5 * (left + right) + window.scrollX, 150, window.innerWidth - 150)
    popoverOpen.value = true
    popoverPosition.value = {
      top: clampedTop + 'px',
      left: clampedLeft + 'px',
    }
  }
}
function getBoundingClientRectsInRange(
  from: { captionIndex: number; wordIndex: number; offset: number; },
  to: { captionIndex: number; wordIndex: number; offset: number; }
) {

  const fromWordIndex = overallWordIndex(from.captionIndex, from.wordIndex)
  const toWordIndex = overallWordIndex(to.captionIndex, to.wordIndex)

  const rects = [] as DOMRect[]

  for (let i = from.captionIndex; i <= to.captionIndex; i++) {
    const caption = editorCaptionsStore.captions[i]
    for (let j = 0; j <= caption.words.length - 1; j++) {
      const wordIndex = overallWordIndex(i, j)
      if (wordIndex >= fromWordIndex && wordIndex <= toWordIndex) {
        const element = document.getElementById('word-' + caption.words[j].id)
        if (element) {
          rects.push(element.getBoundingClientRect())
        }
      }
    }
  }
  return rects
}
useEventListener('selectstart', onSelectStart)
async function deleteWord(captionIndex: number, wordIndex: number) {
  const caption = editorCaptionsStore.captions[captionIndex]
  currentSelection.value = null
  if (caption) {
    if (caption.words.length === 1) {
      editorCaptionsStore.deleteCaption(caption.id)
    } else {
      const words = caption.words.filter((_, i) => i !== wordIndex)
      editorCaptionsStore.updateCaption(caption.id, { words })
    }
  }
}
async function deleteWordOrSelection() {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    historyStore.transaction('CAPTIONS:DELETE', async () => {
      if (from.captionIndex === to.captionIndex && from.wordIndex === to.wordIndex) {
        await deleteWord(from.captionIndex, from.wordIndex)
      } else {
        await deleteCurrentSelection()
      }
      focusedElement.value = null;
      popoverOpen.value = false;
    });
  }
}
async function deleteCurrentSelection() {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    removeCharactersInRange(from, to)
  }
}
function overallWordIndex(captionIndex: number, wordIndex: number) {
  const captionsBefore = editorCaptionsStore.captions.slice(0, captionIndex)
  return sumBy(captionsBefore, (c) => c.words.length) + wordIndex
}
function removeCharactersInRange(
  from: { captionIndex: number; wordIndex: number; offset: number; },
  to: { captionIndex: number; wordIndex: number; offset: number; }
) {
  const fromWordIndex = overallWordIndex(from.captionIndex, from.wordIndex)
  const toWordIndex = overallWordIndex(to.captionIndex, to.wordIndex)
  const updates = new Map<string, StoredWord[]>()

  for (let i = from.captionIndex; i <= to.captionIndex; i++) {
    const caption = editorCaptionsStore.captions[i]
    const words = [] as StoredWord[]
    for (let j = 0; j <= caption.words.length - 1; j++) {
      const word = caption.words[j]
      const wordIndex = overallWordIndex(i, j)
      const strippedText = strip.value(word)
      const text = updateText(strippedText, wordIndex, fromWordIndex, toWordIndex, from.offset, to.offset)
      if (text.trim().length > 0) {
        words.push({ ...word, text })
      }
    }
    updates.set(caption.id, words)
  }

  currentSelection.value = null
  for (const [id, words] of updates) {
    if (words.length === 0) {
      editorCaptionsStore.deleteCaption(id)
    } else {
      editorCaptionsStore.updateCaption(id, { words })
    }
  }
}
const strip = useStripPunctuation()
function updateText(text: string, i: number, start: number, end: number, startOffset: number, endOffset: number): string {
  if (i === start) {
    return text.slice(0, startOffset)
  } else if (i === end) {
    return text.slice(endOffset)
  } else if (i > start && i < end) {
    return ''
  } else {
    return text
  }
}
const captionColor = computed({
  get() {
    return colorOfSelection.value
  },
  set(color) {
    if (color !== undefined) {
      updateCurrentColors(color)
    }
  }
})
async function updateCurrentColors(color: string | null) {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    updateColorsInRange(color, from, to)
  } else {
    const caption = editorCaptionsStore.captions.find((c) => c.id === currentCaptionId.value)
    if (caption) {
      editorCaptionsStore.updateCaption(caption.id, {
        words: caption.words.map((w) => w.id === currentWordId.value ? { ...w, color } : w)
      })
    }
  }
}
function updateColorsInRange(
  color: string | null,
  from: { captionIndex: number; wordIndex: number; offset: number; },
  to: { captionIndex: number; wordIndex: number; offset: number; }
) {
  const fromWordIndex = overallWordIndex(from.captionIndex, from.wordIndex)
  const toWordIndex = overallWordIndex(to.captionIndex, to.wordIndex)
  for (let i = from.captionIndex; i <= to.captionIndex; i++) {
    const caption = editorCaptionsStore.captions[i]
    const words = [] as StoredWord[]
    for (let j = 0; j <= caption.words.length - 1; j++) {
      const word = caption.words[j]
      const wordIndex = overallWordIndex(i, j)
      if (wordIndex >= fromWordIndex && wordIndex <= toWordIndex) {
        words.push({ ...word, color: color })
      } else {
        words.push(word)
      }
    }
    editorCaptionsStore.updateCaption(caption.id, { words })
  }
}
async function deleteEmoji() {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    if (from.captionIndex === to.captionIndex) {
      const caption = editorCaptionsStore.captions[from.captionIndex]
      logging.trackEvent('Editor Emoji Deleted', {
        text: caption.text,
        emoji: caption.emojis?.[0],
        language: editorCaptionsStore.selectedLanguage
      });
      editorCaptionsStore.updateCaption(caption.id, { emojis: [] })
    }
  }
}
async function updateEmoji(emoji: { i: string }) {
  if (currentSelection.value) {
    const { from, to } = currentSelection.value
    if (from.captionIndex === to.captionIndex) {
      const caption = editorCaptionsStore.captions[from.captionIndex]
      if (caption.emojis?.length && caption.emojis?.length > 0) {
        // If the emoji is updated, track it as updated
        logging.trackEvent('Editor Emoji Updated', {
          text: caption.text,
          oldEmoji: caption.emojis[0],
          newEmoji: emoji.i,
          language: editorCaptionsStore.selectedLanguage
        })
      } else {
        // If the emoji is added, track it as added
        logging.trackEvent('Editor Emoji Added', {
          text: caption.text,
          emoji: emoji.i,
          language: editorCaptionsStore.selectedLanguage
        })
      }
      editorCaptionsStore.updateCaption(caption.id, { emojis: [emoji.i] })
    }
  }
}
const { colorMode } = useTheme()
const autoScroll = ref(true);
let timeout: NodeJS.Timeout
function onScroll() {
  autoScroll.value = false;
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    autoScroll.value = true;
  }, 1000)
}
watch(currentWordId, () => {
  if (!autoScroll.value || !container.value || !currentWordId.value) {
    return
  }

  const activeElement = document.getElementById('word-' + currentWordId.value)
  if (!activeElement) {
    return
  }

  const rect = activeElement.getBoundingClientRect()
  const containerRect = container.value.getBoundingClientRect()

  if (rect.top < containerRect.top) {
    container.value.scrollTop -= containerRect.top - rect.top + 5
  } else if (rect.bottom > containerRect.bottom) {
    container.value.scrollTop += rect.bottom + 5 - containerRect.bottom
  }
})
</script>

<template>
  <div class="flex flex-row flex-wrap gap-2 items-center justify-between select-none">
    <Button variant="depressed" size="sm" @click="addCaption">
      <IconSaxAddSquare class="w-4 h-4" />
      Add caption
    </Button>
    <span class="flex md:hidden items-center text-xs font-light h-full">
      <IconSaxInfoCircle class="w-4 h-4 mr-1" />
      Double tap to edit a caption
    </span>
  </div>
  <div
    ref="container"
    data-contenteditable="true"
    class="relative flex w-full flex-wrap gap-1 overflow-auto border-t border-gray-200 pt-2"
    @scroll="onScroll"
    draggable="false"
  >
    <CaptionEditorLine
      @select="onSelectEnd"
      v-for="caption in filteredCaptions" :key="caption.id" :caption="caption"
      v-model:focus="focusedElement"
      v-model:popover-open="popoverOpen"
    />
  </div>
  <div class="flex pt-8">
    <Button
      v-if="focusedElement === null"
      @click="focusOnCurrentWord"
      variant="depressed" size="sm"
    >
      <EditIcon class="w-4" />
      Edit captions
    </Button>
    <Button v-else @click="focusedElement.blur()" size="sm" variant="primary">
      <CheckMarkIcon class="h-4 w-4 fill-current" />
      Finish editing
    </Button>
    <div class="flex-grow"></div>
    <Button variant="ghost" size="square" @click="resetCaptions">
      <ResetIcon class="w-4" />
    </Button>
  </div>
  <div
    ref="popover"
    :class="popoverOpen ? '' : 'invisible scale-75 opacity-0 translate-y-4'"
    class="mt-0.5 p-1 w-[295px] fixed z-50 -translate-x-1/2 rounded-md border border-surface-panel-border bg-popover text-popover-foreground shadow-md outline-none transition-[transform,_opacity]" :style="popoverPosition"
  >
    <div class="flex w-full flex-wrap items-center justify-center gap-0.5">
      <template v-if="!editorCaptionsStore.captionStyleSettings.disableHighlight">
        <CaptionColorSelector v-model:color="captionColor" :caption-style-settings="editorCaptionsStore.captionStyleSettings" />
        <div class="mx-1 mt-2 h-5 w-px border-l border-gray-100" />
      </template>
      <Popover>
        <PopoverTrigger as-child class="p-0 m-0 grid place-items-center">
          <Button variant="ghostDestructive" class="w-7 h-7 p-0.5">
            <EmojiIcon class="fill-current" />
          </Button>
        </PopoverTrigger>
        <PopoverContent class="bg-none flex flex-col items-center gap-0.5 layer-2">
          <EmojiPicker class="!bg-none !bg-transparent !shadow-none !m-0 !transition-all [&_*]:!transition-all" :native="true" @select="updateEmoji" :theme="colorMode" />
          <Button variant="ghostDestructive" @click="deleteEmoji">
            <TrashcanIcon class="fill-current w-4 h-4" /> Delete emoji
          </Button>
        </PopoverContent>
      </Popover>
      <Button variant="ghostDestructive" @click="deleteWordOrSelection" class="w-7 h-7 p-0.5">
        <TrashcanIcon />
      </Button>
    </div>
  </div>
  <AlertDialog v-model:open="isRevealed">
    <AlertDialogContent>
      <AlertDialogTitle class="text-lg font-bold">Reset captions</AlertDialogTitle>
      <AlertDialogDescription>
        Are you sure you wish to undo all changes and reset all captions to how the AI originally generated them?
      </AlertDialogDescription>
      <AlertDialogFooter>
        <Button variant="outline" @click="cancel">Cancel</Button>
        <Button variant="destructive" @click="confirm">Reset</Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss">

</style>
