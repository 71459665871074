import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { postApiRenders, getApiRenders } from '@/apis/streamladder-api/renders/renders'
import { useUserInfoStore } from '@/store/user/userInfo'
import { isBefore, subMinutes } from 'date-fns'
import { useDebounceFn } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import logging from '@/logging'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { type CreateRenderDto, RendererType } from '@/apis/streamladder-api/model'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useRendererData } from '@/areas/editor/store/useRendererData'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { requestUserSignInAsync } from '@/authentication/supabase'
import { tiers } from '@/enums/tiers'
import { selectCaptionPresetByActiveCaptionStyles } from '@/areas/editor/store/selectors/selectActiveCaptionPresetVariants'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

export const useStreamLadderRender = () => {

  const videoStore = useVideoStore()
  const stickersStore = useStickersStore()
  const segmentsStore = useSegmentsStore()
  const cropsStore = useCropsStore()
  const effectsStore = useEffectsStore()

  const { rendererSegments } = useRendererData()
  const clipInfoStore = useEditorClipInfoStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const captionPreset = selectCaptionPresetByActiveCaptionStyles();
  const editorMainStore = useEditorMainStore()

  const captionsStore = useCaptionsStore()

  const fileUploads = useFileUploads()
  const user = useUserInfoStore()

  const preprocess = async () => {
    // Make sure the user is authenticated
    // Wait for the user to authenticate
    const userIsAuthenticated = await requestUserSignInAsync('Please login to render your video')
    if (!userIsAuthenticated)
      return {
        type: 'error',
        message: 'User is not authenticated',
      } as const

    if (stickersStore.ids.length > 0 && !canGuardWithPopup('stickers')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to stickers',
      } as const
    }

    if ((editorCaptionsStore.hasCaptions || captionsStore.entities.length > 0) && !canGuardWithPopup('captions')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to captions',
      } as const
    }

    const hasGiphyStickers = stickersStore.entities.some((sticker) => sticker.type === 'giphy')
    if (hasGiphyStickers && !canGuardWithPopup('giphy-stickers')) {
      return {
        type: 'interrupted',
        message: 'User does not have enough access to Giphy stickers',
      } as const
    }

    const canServerSideRender = canGuard('server-side-rendering')
    if (clipInfoStore.isLocalFile) {

      const clipId = clipInfoStore.id as string
      const upload = fileUploads.selectById(clipId).value

      if (upload) {
        try {
          await upload.suspense().catch(console.error)
          await clipInfoStore.setClipByUploadId(clipId)

          if (upload.status !== 'finished') {
            Sentry.captureException(new Error(`Failed to upload file; upload status is '${upload.status}'`))
            return {
              type: 'error',
              message: `Failed to upload file; upload status is '${upload.status}'`,
            } as const
          }
        } catch (reason) {
          console.error(reason, JSON.stringify(upload))
          Sentry.captureException(new Error(`Upload failed with error '${reason}`))
          return {
            type: 'error',
            message: `Upload failed with error '${reason}'`,
          } as const
        }
      }
    }

    if (canServerSideRender) {
      // make sure the user has their file uploaded if they use server side queueing

      const canQueueRenders = canGuard('server-side-queueing')
      if (!canQueueRenders) {
        return {
          type: 'server',
          status: 'wait',
        } as const
      }

      // redirect to preprocess where the client will collect data and send it to the server
      return {
        type: 'server',
        status: 'ready',
      } as const
    }

    return {
      type: 'error',
      message: 'No render type',
    } as const
  }

  const trackClipCreation = (options: Awaited<ReturnType<typeof preprocess>>) => {
    const eventProperties = {
      Layout: rendererSegments.value[0].layout,
      Source: clipInfoStore.source,
      ...{
        StickersUsed: stickersStore.entities.map((s) => s.key),
        StickerCount: stickersStore.ids.length,
        StickerCountTimed: stickersStore.entities.filter((sticker) => sticker.startMs !== 0 && sticker.endMs !== videoStore._duration * 1000).length,
      },
      ...editorCaptionsStore.getLoggingData(),
      ...{
        FragmentCount: cropsStore.ids.length,
        SegmentCount: segmentsStore.whereIsNotZoom().value.length,
        ZoomCount: segmentsStore.whereIsZoom().value.length,
      },
      SelectedResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      SegmentCount: videoStore.segments.length,
    }

    if (options.type === 'interrupted') {
      logging.trackEvent('Clip Creation Interrupted', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    if (options.type === 'error') {
      logging.trackEvent('Clip Creation Error', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    logging.trackEvent('Clip Created', {
      ...eventProperties,
      renderer: options.type,
      Queued: options.type === 'server' && options.status === 'ready',
    })
  }

  const getVideoRenderData = (captionsV2Enabled = false, isPremium = false) => {
    return {
      contentUrl: clipInfoStore.mp4Url,
      title: clipInfoStore.title ?? 'Clip',
      segments: rendererSegments.value,
      outputFPS: user.tier === tiers.FREE ? 30 : 60,
      outputResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      overlay: {
        ProjectId: clipInfoStore.id,
        Effects: effectsStore.entities,
        Stickers: stickersStore.entities,
        Captions: editorCaptionsStore.captions,
        CaptionsWrapper: editorCaptionsStore.captionsWrapper,
        DurationMs: videoStore._duration * 1000,
        captionsObject: {
          CaptionStyleSettings: editorCaptionsStore.captionStyleSettings,
          BaseOptions: editorCaptionsStore.baseOptions,
          captionPreset: captionPreset.value,
        },
        captionsV2: captionsV2Enabled ? {
          captions: captionsStore.rendererEntities,
          baseOptions: captionsStore.baseOptions,
          captionPreset: captionsStore.baseCaptionPreset,
          captionsArea: captionsStore.captionsArea
        } : null,
      },
      isPremium: isPremium,
    } as CreateRenderDto
  }

  const renderVideoOnServer = async () => {
    try {
      const renderData = getVideoRenderData()
      const res = await postApiRenders(renderData)
      if (res == null)
        return {
          type: 'error',
          message: 'No response',
        } as const

      const taskId = res.value?.id
      if (!taskId)
        return {
          type: 'error',
          message: 'No taskId',
        } as const
      return {
        type: 'server',
        task: res.value,
      } as const
    } catch (error) {

      Sentry.captureException(error)

      if (error?.response?.data?.message === 'Video is already rendering') {
        return {
          type: 'error',
          message: 'Video is already rendering',
        } as const
      } else {
        return {
          type: 'error',
          message: 'Server error',
        } as const
      }
    }
  }

  const trackClipServerRender = (options: Awaited<ReturnType<typeof renderVideoOnServer>>) => {
    const eventProperties = {
      Layout: rendererSegments.value[0].layout,
      ...{
        StickersUsed: stickersStore.entities.map((s) => s.key),
        StickerCount: stickersStore.ids.length,
        StickerCountTimed: stickersStore.entities.filter((sticker) => sticker.startMs !== 0 && sticker.endMs !== videoStore._duration * 1000).length,
      },
      ...editorCaptionsStore.getLoggingData(),
      ...{
        FragmentCount: cropsStore.ids.length,
        SegmentCount: segmentsStore.whereIsNotZoom().value.length,
        ZoomCount: segmentsStore.whereIsZoom().value.length,
      },
      SelectedResolution: editorMainStore.outputWidth === 1080 ? 1080 : 720,
      SegmentCount: videoStore.segments.length,
    }

    if (options.type === 'error') {
      logging.trackEvent('Clip Server Render Error', {
        ...eventProperties,
        Error: options.message,
      })
      return
    }

    logging.trackEvent('Clip Server Rendering', {
      ...eventProperties,
      taskId: options.task,
    })
  }

  const requestVideoRender = useDebounceFn(async () => {
    try {
      // Validate the current editor state
      // make sure the user is authenticated
      // make sure the user has a subscription
      // make sure the users does not use features that are not allowed
      // return the type of rendering required
      const preprocessResult = await preprocess()
      console.log('preprocessResult', preprocessResult)
      trackClipCreation(preprocessResult)
      if (preprocessResult.type === 'error') return preprocessResult
      /* @ts-ignore - I don't dare touch this rn */
      if (preprocessResult.type === 'local') {
        // user is on the client, rendering the video.
        // The url contains all relevant data to render the video
        // for cors reasons we need hard redirect to the url
        /* @ts-ignore - I don't dare touch this rn */
        console.log('redirecting to', preprocessResult.generateUrl)
        /* @ts-ignore - I don't dare touch this rn */
        window.location.href = preprocessResult.generateUrl
        return preprocessResult
      }
      if (preprocessResult.type === 'interrupted') {
        // user is not allowed to render
        return preprocessResult
      }

      if (preprocessResult.type === 'server') {
        // The rendering needs to be done on the server
        if (preprocessResult.status === 'wait') {
          // make sure a silver user does not have more than one render
          // wait for latest render information
          // if the user is rendering, show a popup
          const isRendering = await fetchIsAlreadyRendering()
          if (isRendering) {
            const canContinue = canGuardWithPopup('server-side-queueing', {
              title: 'One at a Time, Please!',
              subtitle:
                'Your Silver Membership allows one active render. Choose to wait, or upgrade to Gold for multiple renders.',
            })
            if (!canContinue) {
              return {
                type: 'error',
                message: 'There is already a render running'
              }
            }
          }
        }
        // user is ready to render on the server

        const renderResult = await renderVideoOnServer()
        trackClipServerRender(renderResult)
        if (renderResult.type === 'error') return renderResult
        if (renderResult.type === 'server') {
          // trigger a refresh of the render list
          // this wil trigger a poll
          return renderResult
        }
      }
    } catch (e: any) {
      Sentry.captureException(e)
      return {
        type: 'error',
        message: e.message,
      }
    }
    return {
      type: 'error',
      message: 'Error',
    }
  }, 1000)

  async function getAllRenders() {
    const response = await getApiRenders();
    return response?.value?.items ?? [];
  }

  async function requestWebcodecRender(renderOnServer: boolean, renderData: CreateRenderDto) {
    const renderDataWithoutStartingRenderingProcess = {
      ...renderData,
      rendererType: renderOnServer ? RendererType.serverSideWebCodecRenderer : RendererType.webCodecRenderer,
    }

    // For some reason, the title is sometimes not being set in the renderData object.
    // This is a temporary fix to ensure that the title is always set.
    if (!renderDataWithoutStartingRenderingProcess.title) {
      renderDataWithoutStartingRenderingProcess.title = 'Clip'
    }

    console.log('Render data:', JSON.stringify(renderDataWithoutStartingRenderingProcess))

    const response = await postApiRenders(renderDataWithoutStartingRenderingProcess).catch((e) => {
      console.error(JSON.stringify(e))
      Sentry.captureException(new Error("Can't send render data to server."))
      return null
    })

    if (renderOnServer) {
      logging.trackEvent('WebCodec Renderer Render On Server', {
        renderDataId: response?.value?.id
      })
    }

    return response?.value?.id;
  }

  return {
    preprocess,
    renderVideoOnServer,
    requestVideoRender,
    getVideoRenderData,
    requestWebcodecRender,
    getAllRenders,
  }
}

async function fetchIsAlreadyRendering() {

  const userInfoStore = useUserInfoStore()
  if (!userInfoStore.isLoggedIn) return false

  const response = await getApiRenders({ Status: 'rendering' })
  const renders = response.value?.items ?? []

  // date-fns function to check if a render is older than 10 minutes using date-fns library
  return renders.some((render) => {
    const renderCreatedAt = new Date(render.createdAt ?? 0)
    const tenMinutesAgo = subMinutes(new Date(), 10)
    return render.status === 'rendering' && !isBefore(renderCreatedAt, tenMinutesAgo)
  })
}
