<script setup lang="ts">
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import { computed, ref, watch } from 'vue'
import { Button } from '@/components/ui/button'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { getApiRendersPremiumRenderCount } from '@/apis/streamladder-api/renders/renders'
import { useBlackFriday } from '@/Hooks/dates/useBlackFriday'
import type { upgradeDialog } from '@/helpers/upgradeDialog';

const userInfoStore = useUserInfoStore()

const emit = defineEmits<{
  (event: 'openUpgradeDialog', payload?: Parameters<typeof upgradeDialog['open']>[0]): void
}>()

const showBanner = computed(() => {
  return userInfoStore.isLoggedIn 
    && userInfoStore.hasLoadedUserInfo
    && userInfoStore.tier !== tiers.GOLD 
    && userInfoStore.tier !== tiers.SILVER
})

const isFreeUser = computed(() => {
  return userInfoStore.tier === tiers.FREE;
});

const isInPremiumExperiment = useFeatureFlagVariantEnabled('max-5-premium-exports', 'premium');
const premiumRenderCount = ref<number | null>(null);

watch(isInPremiumExperiment, async () => {
  if (isInPremiumExperiment.value) {
    const response = await getApiRendersPremiumRenderCount();
    if (response) {
      premiumRenderCount.value = response.value as number;
    }
  }
}, { immediate: true });

const { showBlackFridayDeal } = useBlackFriday();
</script>

<template>
  <div v-if="showBlackFridayDeal"
    @click="emit('openUpgradeDialog', { reason: 'Black Friday Editor Upgrade Banner' })"
    class="sticky -mb-[1px] flex w-full items-center min-h-10 justify-center bg-[#070033] text-white font-title font-normal cursor-pointer"
  >
    <img class="hidden lg:block h-full w-auto absolute left-0" src="/black-friday/ribbon-l.png" alt="Black Friday Ribbon" />
    <div class="flex flex-col sm:flex-row items-center justify-between gap-2 relative z-[1] p-1.5">
      <span class="stroke-text">
        This Black Friday, get 50% GOLD Yearly subscription with code: <pre class="inline text-sm">BLACKFRIDAY24</pre>
      </span>
      <Button size="sm" class="bg-[#F70000] hover:bg-[#DD0000] uppercase shadow-[0_0_1em_0_#F70000] self-end md:self-center">
        Claim now
      </Button>
    </div>
    <img class="hidden lg:block h-full w-auto absolute right-0" src="/black-friday/ribbon-r.png" alt="Black Friday Ribbon" />
  </div>
  <div
    v-else-if="showBanner"
    @click="emit('openUpgradeDialog')"
    class="sticky -mb-[1px] flex w-full items-center justify-center bg-[#ffc22d] p-1.5 cursor-pointer"
  >
    <template v-if="isFreeUser && isInPremiumExperiment && premiumRenderCount < 4">
      <div class="flex h-full items-center justify-center pl-4 gap-4 text-black/75 leading-[0]">
        <p class="hidden text-sm font-bold sm:block">
          You have <span class="font-extrabold">{{ 5 - premiumRenderCount }}</span> Free Exports with Premium Features left. Upgrade now to keep using Premium Features.
        </p>
        <p class="font-bold text-sm sm:hidden">
          Unlock more features.
        </p>
        <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
          Upgrade <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </Button>
      </div>
    </template>
    <template v-else-if="isFreeUser && isInPremiumExperiment && premiumRenderCount === 4">
      <div class="flex h-full items-center justify-center pl-4 gap-4 text-black/75 leading-[0]">
        <p class="hidden text-sm font-bold sm:block">
          You can export <span class="font-extrabold text-lg text-company-primary-200">1</span> more time for Free with any Premium Feature. Upgrade Now to keep using Premium Features.
        </p>
        <p class="font-bold text-sm sm:hidden">
          Unlock more features.
        </p>
        <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
          Upgrade <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </Button>
      </div>
    </template>
    <template v-else-if="isFreeUser">
      <div class="flex h-full items-center justify-center pl-4 gap-4 text-black/75 leading-[0]">
        <p class="hidden text-sm font-bold sm:block">
          You're using the <span class="capitalize">{{ tiers.toString(userInfoStore.tier) }}</span> Plan of StreamLadder. Upgrade to export without watermarks.
        </p>
        <p class="font-bold text-sm sm:hidden">
          Unlock more features.
        </p>
        <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
          Upgrade <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </Button>
      </div>
    </template>
    <template v-else>
      <div class="flex h-full items-center justify-center pl-4 gap-4 text-black/75 leading-[0]">
        <p class="hidden text-sm font-bold sm:block">
          You're using the <span class="capitalize">{{ tiers.toString(userInfoStore.tier) }}</span> Plan of StreamLadder with limited features.
        </p>
        <p class="font-bold text-sm sm:hidden">
          Unlock more features.
        </p>
        <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
          Upgrade <IconSaxArrowRight class="ml-1 h-4 w-4" />
        </Button>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
@use '@/assets/mixins/text' as text;

.stroke-text {
  @include text.stroke(#070033, 1);
}
</style>
