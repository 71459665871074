import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import type { ClipDto } from '../@type/ClipDto';
import { listenForClipDownload } from '@/areas/editor/startup/listenForClipDownload';

export async function startupFromYoutubeClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }
    const clip = await getApiYouTubeClipsClipId(clipId) as unknown as ClipDto
    config.signal?.throwIfAborted()

    const response = await downloadClipIfMp4UrlIsEmpty(clip, config)
    config.signal?.throwIfAborted()
    if ('error' in response) {
      if (response.error) {
        editorClipInfoStore.loadingState = {
          state: 'error',
          ...explanationFor(response.error)
        };
      } else {
        return { error: response.error };
      }
    }

    return { error: null, clip: response }
  } catch (error) {
    return handleStartupError(error)
  }
}

async function downloadClipIfMp4UrlIsEmpty(clip: ClipDto, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()

  // If we get an empty response from a YouTube clip, we have to listen to the pusher client to retrieve the info needed.
  if (clip.mp4Url === '') {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Importing YouTube Clip. This may take a few minutes...'
    }

    console.log('Empty response, listening to pusher client')
    try {
      return await listenForClipDownload(clip, config)
    } catch (e) {
      return { error: e?.toString() ?? null }
    }
  } else {
    return clip
  }
}

function explanationFor(error: string) {
  switch (error) {
    case 'blocked_video':
      return {
        description: 'This video is geo-blocked in the USA and could not be imported. Please ensure the original video is available in the USA.'
      }
    default:
      return {
        title: 'Could not import clip 🚫',
        description: 'Looks like your clip is either still processing on YouTube (stream clips take up to 24 hours), age-restricted or it wasn\'t found. Try another clip or check back later!'
      }
  }
}
