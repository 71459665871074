<script lang="ts" setup>
import settings from '@/data/settings'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Button } from '@/components/ui/button'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { retryAsync } from '@/helpers/retry'
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import IconSaxEye from '@/components/Icons/iconsax/IconSaxEye.vue'
import IconSaxCalendar from '@/components/Icons/iconsax/IconSaxCalendar.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxUser from '@/components/Icons/iconsax/IconSaxUser.vue'
import IconSaxCloud from '@/components/Icons/iconsax/IconSaxCloud.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { posthog } from 'posthog-js'
import { downloadKickClip } from '@/Hooks/useKickClip'
import { listenForClipDownload } from '@/areas/editor/startup/listenForClipDownload';
import type { ClipDto } from '@/areas/editor/@type/ClipDto';

const loadingState = ref<{
  state: 'loading' | 'error';
  title?: string;
  description: string;
} | null>(null)

const clip = ref<ClipDto | null>(null)

const fallbackSourceClipTitle = ref('YouTube Clip');

const route = useRoute()
onMounted(async () => {

  const source = route.params.source as string
  const videoId = route.params.id as string

  if (source === 'youtube-clip') {
    fallbackSourceClipTitle.value = 'YouTube Clip'
  } else if (source === 'twitch-clip') {
    fallbackSourceClipTitle.value = 'Twitch Clip'
  } else if (source === 'kick-clip') {
    fallbackSourceClipTitle.value = 'Kick Clip'
  }

  try {
    clip.value = await fetchClipInfo(source, videoId)
    loadingState.value = null
  } catch (error) {
    loadingState.value = {
      state: 'error',
      title: 'Something went wrong',
      description: 'We could not load the clip. Please try again later.',
    }
  }
})

async function fetchClipInfo(source: string, videoId: string) {
  switch (source) {
    case 'twitch-clip':
      return await fetchTwitchClipInfo(videoId)
    case 'youtube-clip':
      return await fetchYoutubeClipInfo(videoId)
    case 'kick-clip':
      return await fetchKickClipInfo(videoId)
    default:
      throw new Error('Invalid source')
  }
}

async function fetchKickClipInfo(videoId: string) {

  loadingState.value = {
    state: 'loading',
    description: 'Fetching Kick clip information',
  }

  return await retryAsync(() => downloadKickClip(videoId)
    .then(response => response as Promise<ClipDto>)
    .catch((reason) => {
      if (reason instanceof DOMException && reason.name === 'AbortError') {
        throw reason
      } else {
        throw new Error('Failed to fetch clip info')
      }
    }))
}

async function fetchTwitchClipInfo(videoId: string) {

  loadingState.value = {
    state: 'loading',
    description: 'Fetching Twitch clip information',
  }

  return await retryAsync(() => fetch(`https://streamladder-twitchclipimporter.fly.dev/clip/${videoId}`)
    .then(response => response.json() as Promise<ClipDto>)
    .catch((reason) => {
      if (reason instanceof DOMException && reason.name === 'AbortError') {
        throw reason
      } else {
        throw new Error('Failed to fetch clip info')
      }
    }))
}

async function fetchYoutubeClipInfo(videoId: string) {

  loadingState.value = {
    state: 'loading',
    description: 'Fetching YouTube clip information',
  }

  const clip = await retryAsync(() => getApiYouTubeClipsClipId(videoId) as unknown as Promise<ClipDto>)
  if (clip.mp4Url) {
    return {
      ...clip,
      title: clip.title || 'YouTube Clip',
    }
  } else {
    loadingState.value = {
      state: 'loading',
      description: 'Importing YouTube Clip. This may take a few minutes...',
    }
    return await listenForClipDownload(clip)
  }
}

const router = useRouter()
async function startup() {

  const source = route.params.source as string
  const videoId = route.params.id as string

  await router.push({ name: 'editor/[clipSource]/[clipId]', params: { clipSource: source, clipId: videoId } });
}

function onDownload() {
  posthog.capture('Clip Downloader Clip Downloaded', { source: route.params.source })
}
</script>

<template>
  <main class="flex flex-col gap-8 p-4 lg:gap-16 lg:p-12 h-full">
    <h1 class="font-bold text-3xl lg:text-4xl">Download or Edit Clip</h1>
    <template v-if="loadingState">
      <Transition
        appear
        enter-active-class="transition-[transform,_opacity] duration-300"
        enter-from-class="opacity-0 translate-y-4"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition-[transform,_opacity] duration-300"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-4"
      >
        <div :key="loadingState.state" class="grid place-items-center p-8 w-full h-full">
          <div class="flex flex-col gap-1 items-center text-center w-full max-w-[420px]">
            <template v-if="loadingState.state === 'loading'">
              <LottieAnimation auto-play class="w-36" loop url="/lottie/montage-maker-loader.json" />
              <h2 class="text-xl font-semibold leading-snug font-title">
                Preparing your clip
              </h2>
              <div class="relative w-full flex justify-center">
                <Transition
                  appear
                  enter-active-class="transition-[transform,_opacity] duration-300"
                  enter-from-class="opacity-0 translate-x-4"
                  enter-to-class="opacity-100 translate-x-0"
                  leave-active-class="transition-[transform,_opacity] duration-300"
                  leave-from-class="opacity-100 translate-x-0"
                  leave-to-class="opacity-0 -translate-x-4"
                >
                  <p :key="loadingState.description" class="font-light absolute inset-auto" style="text-wrap: pretty;">
                    {{ loadingState!.description }}
                  </p>
                </Transition>
              </div>
            </template>
            <template v-else>
              <LottieAnimation :loop="false" class="w-24 h-24 mb-6" url="/lottie/error.json" />

              <h2 class="text-xl font-semibold leading-snug font-title">
                {{ loadingState.title ?? 'Something went wrong' }}
              </h2>
              <p :key="loadingState.description" class="font-light" style="text-wrap: pretty;">
                {{ loadingState!.description }}
              </p>

              <div class="w-full flex flex-col items-center justify-center mt-6 gap-2">
                <RouterLink :to="{ name: dashboardRouteNames.downloader.root }">
                  <Button variant="primary">
                    Try a different video
                  </Button>
                </RouterLink>
                <Button :href="settings.discordInviteUrl" as="a" class="font-normal" target="_blank" variant="ghost">
                  <DiscordLogo class="shrink-0 w-5 h-5 fill-current" />
                  Open a ticket
                </Button>
              </div>
            </template>
          </div>
        </div>
      </Transition>
    </template>
    <template v-else-if="clip">
      <section class="flex flex-col lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-8 h-full">
        <video
          :src="clip.mp4Url!" loop playsinline autoplay disablePictureInPicture disableRemotePlayback controls
          class="grow-0 shrink-0 xl:col-span-2 w-full rounded-2xl border-4 border-black"
          @load="loadingState = null"
          @error="loadingState = {
            state: 'error',
            title: 'Something went wrong',
            description: 'We could not load the clip. Please try again later.',
          }"
        />
        <div class="flex flex-col self-stretch justify-between h-full gap-4 lg:justify-evenly">
          <div>
            <h1 class="text-2xl lg:text-4xl flex font-semibold">
              {{ clip.title ?? fallbackSourceClipTitle }}
            </h1>

            <h2 v-if="'gameName' in clip && clip.gameName" class="text-base font-normal">
              Playing
              <span class="inline-flex">
                <span class="font-serif font-black text-base">&ldquo;</span>
                  {{ (clip.gameName) }}
                <span class="font-serif font-black text-base">&rdquo;</span>
              </span>
            </h2>
            <div
              v-if="route.params.source === 'twitch-clip'"
              class="flex items-center gap-2 opacity-75 font-semibold text-xs leading-[1] my-2"
            >
              <div class="flex items-center gap-1">
                <IconSaxUser class="w-3 h-3" />
                {{ clip.broadcasterName }}
              </div>
              &bullet;
              <div class="flex items-center gap-1">
                <IconSaxEye class="w-3 h-3" />
                {{ clip.viewCount }}
              </div>
              &bullet;
              <div class="flex items-center gap-1">
                <IconSaxCalendar class="w-3 h-3" />
                {{ new Date(clip.createdAt).toLocaleDateString() }}
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <Button
              @click="onDownload" :download="'StreamLadder - ' + clip.title" :href="clip.mp4Url"
              as="a"
              class="mt-auto"
              size="lg"
              target="_blank"
            >
              <IconSaxCloud />
              Download 
            </Button>
            <Button size="lg" variant="outline" @click="startup">
              <IconSaxVideoVertical />
              Edit clip
            </Button>
            <RouterLink :to="{ name: dashboardRouteNames.downloader.root }" class="flex">
              <Button class="w-full" size="lg" variant="link">
                <IconSaxArrowLeft />
                Download another clip
              </Button>
            </RouterLink>
          </div>
        </div>
      </section>
    </template>
  </main>
</template>

<style lang="scss" scoped>

</style>
