<script setup lang="ts">
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue'
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useStorage } from '@vueuse/core/index'
import { useFeatureFlags, FEATURE_FLAGS } from '@/Hooks/useFeatureFlagEnabled'
import { useClipboard } from '@vueuse/core'
import QRious from 'qrious'
import IconSaxClipboardExport from '@/components/Icons/iconsax/IconSaxClipboardExport.vue'
import { guessBranchPreviewUrl } from '@/components/Debug/guessBranchPreviewUrl'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useTheme } from '@/Hooks/useTheme'
import { Input } from '@/components/ui/input'
import { useDeploymentVersion } from '@/Hooks/useDeploymentVersion'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import unwrap from '@/helpers/unwrap'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import IconSaxBarcode from '@/components/Icons/iconsax/IconSaxBarcode.vue'
import { isInputTarget } from '@/components/Editor/Timeline/helpers'
import EventBus from '@/eventBus'
import ToastEvents from '@/events/toastEvents'
import { useScreenName } from '@/Hooks/useScreen';

const userInfoStore = useUserInfoStore()

const show = useStorage('debugPopup-open', false)

watch(
  () => userInfoStore.tier,
  (newVal) => {
    userInfoStore.hasSubscription = newVal > 0
    userInfoStore.plan = tiers.toString(newVal)
  }
)

const { copy } = useClipboard()
const featureFlags = useFeatureFlags()

const colorModes = ['light', 'dark', 'auto'] as const
const { colorMode } = useTheme()

function onKeyDown(e: Event) {

  if (isInputTarget(e)) return
  
  if (e instanceof KeyboardEvent) {
    if (e.key === 'Escape') {
      show.value = false
    }

    if (e.key === 'L' && e.shiftKey) {
      const newMode = colorModes[(colorModes.indexOf(colorMode.value) + 1) % colorModes.length]
      colorMode.value = newMode
        EventBus.$emit(ToastEvents.TOAST, {
          title: `Color mode changed to "${newMode}"`,
          timeout: 5000
        });
    }
  }
}

const url = guessBranchPreviewUrl()
const qr = ref<HTMLCanvasElement | null>(null)

onMounted(() => {
  if (qr.value && url) {
    qr.value.innerHTML = ''
    new QRious({
      element: qr.value,
      value: url,
      size: 400,
    })
  }
  
  window.addEventListener('keydown', onKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown)
})

const { data: deployment } = useDeploymentVersion()
const isQrOpen = ref(false)

const screenSize = useScreenName()
</script>

<template>
  <Teleport to="body">
    <div
      class="fixed inset-y-0 z-[1000] flex h-full flex-col transition-[left] layer-1 w-96 drop-shadow-xl"
      :class="{
        '-left-96': !show,
        'left-0': show,
      }"
    >
      <Button variant="ghost" class="absolute left-full top-0 layer-1 p-2 rounded-none" @click="show = !show">
        <ArrowRightIcon class="h-4 w-4 transition" :class="show ? 'rotate-180' : ''" />
      </Button>
      <ScrollArea class="h-full min-h-0">
        <div class="h-full p-4 flex flex-col gap-2">
          <div class="flex flex-col w-full text-xs p-2 opacity-50">
            <h4 class="text-sm">Deployment</h4>
            <pre>Latest: {{ deployment?.latestSha }}</pre>
            <pre v-if="deployment?.hasUpdateReady">Current: {{ deployment?.currentJsSha }}</pre>
          </div>
    
          <section class="p-2 flex flex-col gap-4">
            <header>
              <h4 class="text-lg">General</h4>
              <pre class="font-light opacity-50 text-xs text-left">Screen size: {{ screenSize }}</pre>
            </header>
            
            <div class="flex flex-col gap-1">
              <label class="text-sm">Username</label>
              <Input type="text" v-model="userInfoStore.userName" />
            </div>
      
            <div class="flex flex-col gap-1">
              <label class="text-sm">User Tier</label>
              <LightSwitch
                class="layer-2 mr-auto"
                label-classes="text-white"
                :options="[
                  { label: 'Free', value: tiers.FREE.toString() },
                  { label: 'Silver', value: tiers.SILVER.toString() },
                  { label: 'Gold', value: tiers.GOLD.toString() },
                ]"
                :value="userInfoStore.tier.toString()"
                @update:value="v => userInfoStore.tier = Number(v)"
              />
            </div>
      
            <div class="flex flex-col gap-1">
              <label class="text-sm">Color Mode</label>
              <LightSwitch
                class="layer-2 mr-auto"
                label-classes="text-white"
                :options="[
                  { label: 'Light', value: 'light' },
                  { label: 'Dark', value: 'dark' },
                  { label: 'Auto', value: 'auto' },
                ]"
                v-model:value="colorMode"
              />
            </div>
          </section>
    
          <hr class="my-4 border-zinc-500" />
    
          <section class="p-2 flex flex-col gap-2">
            <h4 class="text-lg">Feature flags</h4>
            <div v-for="[key, flag] in unwrap.entries(FEATURE_FLAGS)" :key="key">
              <label class="text-sm">{{ key }}</label>
              <SelectDropdown v-model="featureFlags[key]" :options="flag.variants.map((v) => ({ label: v, value: v }))" />
            </div>
            <div
              class="form-control"
              v-for="key in Object.keys(featureFlags).filter((k) => !Object.keys(FEATURE_FLAGS).includes(k))"
              :key="key"
            >
              <label class="label flex cursor-pointer flex-col items-stretch space-y-2">
                <span class="label-text text-brand-state-text-primary">{{ key }}</span>
                <template v-if="typeof featureFlags[key] === 'boolean'">
                  <input type="checkbox" v-model="featureFlags[key]" :value="key" class="toggle checked:bg-blue-400" />
                </template>
                <input v-else type="text" v-model="featureFlags[key]" class="input input-sm" />
              </label>
            </div>
          </section>
        </div>
      </ScrollArea>
  
      <template v-if="url !== null">
        <div class="m-4 mb-1 transition-[height] overflow-hidden shrink-0 grow-0" :class="isQrOpen ? 'h-[22rem]' : 'h-0'">
          <canvas ref="qr" class="w-full h-auto" />
        </div>
        <div class="flex w-full flex-row gap-2 p-2">
          <Button variant="ghost" size="square" @click="isQrOpen = !isQrOpen">
            <IconSaxBarcode />
          </Button>
          <Button as="a" :href="url" target="_blank" rel="noopener noreferrer" class="btn-primary btn flex-grow">
            Open branch on server
          </Button>
          <Button variant="ghost" size="square" @click="copy(url)">
            <IconSaxClipboardExport />
          </Button>
        </div>
      </template>
    </div>
  </Teleport>
</template>
