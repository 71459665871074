<script setup lang="ts">
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { ref, computed, watch } from 'vue'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'
import IconSaxSticker from '@/components/Icons/iconsax/IconSaxSticker.vue'
import { Button } from '@/components/ui/button'
import IconSaxExport from '@/components/Icons/iconsax/IconSaxExport.vue'
import IconSaxCrop from '@/components/Icons/iconsax/IconSaxCrop.vue'
import Timeline from '@/areas/editor/timeline/Timeline.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { useHead } from '@unhead/vue'
import { useTheme } from '@/Hooks/useTheme'
import { useViewportSize } from '@/Hooks/useViewportSize'
import '../index.css'
import { TooltipProvider } from '@/components/ui/tooltip'
import { useResizeObserver } from '@vueuse/core'
import { useUserInfoStore, type OutboundSocialAccount } from '@/store/user/userInfo'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxStar from '@/components/Icons/iconsax/IconSaxStar.vue'
import IconSaxLogoutCurve from '@/components/Icons/iconsax/IconSaxLogoutCurve.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import IconSaxSidebarLeft from '@/components/Icons/iconsax/IconSaxSidebarLeft.vue'
import IconSaxArrowLeft2 from '@/components/Icons/iconsax/IconSaxArrowLeft2.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import ExportButton from '@/areas/editor/workspaces/ExportButton.vue'
import IconSaxExportCircle from '@/components/Icons/iconsax/IconSaxExportCircle.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import IconSaxEmojiHappy from '@/components/Icons/iconsax/IconSaxEmojiHappy.vue'
import { PostHog } from '@/logging/post-hog'
import IconSaxFlag from '@/components/Icons/iconsax/IconSaxFlag.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import UpgradeBanner from '@/components-v2/navigation/UpgradeBanner.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { supabase } from '@/authentication/supabase';
import { useRouter } from 'vue-router';
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import Spinner from '@/components/Icons/Spinner.vue'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxSound from '@/components/Icons/iconsax/IconSaxSound.vue'
import { isBrowserSupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import { queryClient } from '@/services/QueryClient';
import WorkspaceWarning from '@/areas/editor/workspaces/WorkspaceWarning.vue';

const clipInfoStore = useEditorClipInfoStore()
const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: [theme],
  },
  htmlAttrs: {
    class: 'prevent-overscroll'
  }
})

const {
  direction,
  selectLabelByStepIndex,
  component: Step,
  currentStep,
  currentSection,
  steps, stepIndex,
  resetEditorStep,
  isLayoutsStep,
} = useEditorStep()

const showWarning = ref(!isBrowserSupported())
const isClipReady = computed(() => !showWarning.value && clipInfoStore.loadingState === null && clipInfoStore.id)

const isSidePanelOpen = ref(true)

const { width: viewportWidth, height: viewportHeight} = useViewportSize()

const nav = ref<HTMLElement | null>(null)
const navWidth = ref(nav.value?.scrollWidth)
useResizeObserver(nav, () => navWidth.value = nav.value?.scrollWidth)

const userInfoStore = useUserInfoStore()
const account = computed(() => {
  const googleAccount = userInfoStore.googleAccount as OutboundSocialAccount
  const twitchAccount = userInfoStore.twitchAccount as OutboundSocialAccount
  return userInfoStore.allSocials.find((s) => s.profileImageUrl) ?? googleAccount ?? twitchAccount ?? null
})

const historyStore = useHistoryStore()
const isLoggingOut = ref(false)

const onLogout = async () => {
  isLoggingOut.value = true

  await supabase.auth.signOut()

  const userInfoStore = useUserInfoStore()
  await userInfoStore.updateUserInfo()
  await queryClient.invalidateQueries()
  window.location.href = router.resolve({ name: 'editor' }).href

  isLoggingOut.value = false
};

const accountMenuOpen = ref(false)

const isBetaTester = useFeatureFlagEnabled('IsBetaTesterV2')

const router = useRouter()
async function newProject() {
  resetEditorStep();
  await historyStore.$reset();
  await router.push({ name: 'editor' });
}

const captions = useGenerateCaptions()
const editorFocusStore = useEditorFocusStore()

watch(() => editorFocusStore.focus, () => {

  const focusType = editorFocusStore.focus?.type

  if (focusType === FocusTypes.SOUND) {
    currentStep.value = editorRouteNames.sounds
    currentSection.value = editorRouteNames.editSounds
  }

  if (!isLayoutsStep.value) {
    return
  }

  if (focusType === FocusTypes.TEXTSTICKER) {
    currentStep.value = editorRouteNames.elements
    currentSection.value = editorRouteNames.text
  } else if (focusType === FocusTypes.STICKER) {
    currentStep.value = editorRouteNames.elements
    currentSection.value = editorRouteNames.stickers
  } else if (focusType === FocusTypes.CAPTION) {
    currentStep.value = editorRouteNames.captions
  }
});
</script>

<template>
  <TooltipProvider :delay-duration="0">
    <div
      class="editor-page layer-0 text-brand-state-text-primary dark:bg-[#0a0a0a]"
      :style="{ width: viewportWidth + 'px', height: viewportHeight + 'px' }"
    >
      <UpgradeBanner class="editor-page__upgrade-banner" @open-upgrade-dialog="(payload) => upgradeDialog.open(typeof payload === 'string' ? payload : {
        tier: 100,
        reason: 'Editor Upgrade Banner Clicked.',
        subtitle: undefined,
        title: undefined,
        intentional: true,
        ...payload
      })" />
      <nav class="editor-page__nav">
        <div class="w-full h-full py-6 px-2 flex flex-col gap-4 bg-gradient-to-tl -from-[12.32%] from-[#A000FE] to-[97.88%] to-[#6080FE] relative">

          <GoldPlanButton v-if="userInfoStore.tier === tiers.GOLD" class="absolute right-2 top-2" :can-click="false" :force-show="true" :small="true" />
          <SilverPlanButton v-else-if="userInfoStore.tier === tiers.SILVER" class="absolute right-2 top-2" :can-click="false" :force-show="true" :small="true" />

          <RouterLink :to="{ name: 'Home' }">
            <Button size="sm" variant="ghost" class="text-white h-[4.5rem] hover:bg-white/20 flex-col items-center w-full p-2 notranslate">
              <span class="text-4xl">SL</span>
            </Button>
          </RouterLink>
          <div class="min-h-0 min-w-[7rem] overflow-y-auto -m-2 p-2 transition-opacity" :class="{ 'pointer-events-none opacity-0': !isClipReady }">
            <div class="flex flex-col gap-4 relative w-full">
              <div v-if="stepIndex >= 0 && isSidePanelOpen && isClipReady"
                class="absolute inset-x-0 h-[4.5rem] rounded-lg bg-white/20 transition-[top] duration-300 z-0 pointer-events-none"
                :style="{ top: `${stepIndex * 5.5}rem` }"
              />
              <Button size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.layouts"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <IconSaxCrop class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Crop</span>
              </Button>
              <Button size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.elements"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <IconSaxSticker class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Elements</span>
              </Button>
              <Button size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.effects"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <IconSaxMagicpen class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Effects</span>
              </Button>
              <Button
                size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.sounds"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <IconSaxSound class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Audio</span>
              </Button>
              <Button size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.captions"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <Spinner v-if="captions.isGeneratingCaptions.value" class="w-10 h-10 animate-spin text-company-primary-50" />
                <IconSaxSubtitle v-else class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Captions</span>
              </Button>
              <Button size="sm" variant="ghost" @click="isSidePanelOpen = true; currentStep = editorRouteNames.export"
                class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2"
              >
                <IconSaxExport class="w-10 h-10" />
                <span class="text-[0.7rem] uppercase">Export</span>
              </Button>
            </div>
          </div>
          <Popover v-model:open="accountMenuOpen" v-if="userInfoStore.isLoggedIn">
            <PopoverTrigger as-child class="mt-auto mx-auto">
              <Button size="sm" variant="ghost" class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2">
                <Avatar class="w-14 h-14">
                  <AvatarImage :src="account?.profileImageUrl" />
                  <AvatarFallback>
                    {{ userInfoStore.userName }}
                  </AvatarFallback>
                </Avatar>
              </Button>
            </PopoverTrigger>
            <PopoverContent align="end" side="right" class="p-1 rounded-xl border-surface-panel-border">
              <nav class="flex flex-col items-stretch gap-1">
                <RouterLink :to="{ name: dashboardRouteNames.dashboard }">
                  <Button class="justify-start w-full" variant="ghost">
                    <IconSaxHomeTrendUp class="w-4 h-4" /> Dashboard
                  </Button>
                </RouterLink>
                <RouterLink :to="{ name: dashboardRouteNames.account.settings }">
                  <Button class="justify-start w-full" variant="ghost">
                    <IconSaxEmojiHappy class="w-4 h-4" /> My Account
                  </Button>
                </RouterLink>
                <Button v-if="PostHog.instance && isBetaTester" class="justify-start w-full" variant="ghost" id="early-access-features-button">
                  <IconSaxFlag class="h-4 w-4" />
                  Beta features
                </Button>
                <Button
                  class="justify-start w-full"
                  @click="accountMenuOpen = false; newProject();"
                  variant="ghost"
                >
                  <IconSaxStar class="w-4 h-4" /> New project
                </Button>
                <Button class="justify-start w-full" variant="ghost" @click="onLogout">
                  <template v-if="isLoggingOut">
                    <div class="border-2 border-current border-t-transparent rounded-full h-4 w-4 animate-spin mr-2.5" />
                    Logging out..
                  </template>
                  <template v-else>
                    <IconSaxLogoutCurve class="h-4 w-4 shrink-0 rotate-180" />
                    Logout
                  </template>
                </Button>
              </nav>
            </PopoverContent>
          </Popover>
          <nav class="mt-auto flex flex-col" v-else>
            <LoginDialog>
              <Button size="sm" variant="ghost" class="text-white h-[4.5rem] hover:bg-white/5 hover:scale-105 flex-col items-center w-full p-2 mt-auto">
                <IconSaxLoginCurve class="h-6 w-6 shrink-0" />
                <span class="text-[0.7rem] uppercase">Login</span>
              </Button>
            </LoginDialog>
          </nav>
        </div>
      </nav>
      <section
        class="editor-page__side-panel relative z-[1] border-r border-surface-panel-border bg-surface-panel layer-1 transition-[width] duration-300 overflow-hidden"
        :class="isClipReady && isSidePanelOpen ? 'md:w-[370px] 2xl:w-[520px]' : 'w-0'"
      >
        <div class="md:w-[370px] 2xl:w-[520px] absolute left-0 top-0 h-auto">
          <Transition
            :enter-from-class="direction === 'backward' ? 'opacity-0 -translate-y-12' : 'opacity-0 translate-y-12'"
            :leave-to-class="direction === 'backward' ? 'opacity-0 translate-y-12' : 'opacity-0 -translate-y-12'"
            enter-active-class="motion-safe:transition-[transform,_opacity]"
            leave-active-class="motion-safe:transition-[transform,_opacity]"
          >
            <component :is="Step" />
          </Transition>
          <Button size="square" variant="ghost" class="absolute top-2 right-2" @click="isSidePanelOpen = !isSidePanelOpen">
            <IconSaxSidebarLeft />
          </Button>
        </div>
      </section>
      <section class="editor-page__timeline flex flex-col min-w-0 relative z-[1]" v-if="isClipReady">
        <div class="border-t border-surface-panel-border bg-surface-panel layer-1 overflow-hidden mt-2">
          <Timeline step="editor" default-open />
        </div>
        <div class="flex items-center justify-between py-2 px-6 border-t border-surface-panel-border">
          <div class="flex items-center gap-2">
            <Button
              variant="depressed"
              v-if="steps[stepIndex - 1]"
              @click="() => {
                currentStep =  steps[stepIndex - 1]?.name ?? steps[0].name;
                isSidePanelOpen = true;
              }"
            >
              <IconSaxArrowLeft2 class="w-4 h-4" />
              {{ selectLabelByStepIndex(stepIndex - 1) }}
            </Button>
          </div>

          <div class="flex items-center gap-2">
            <Button
            variant="gradient" size="lg" class="w-36 "
              v-if="steps[stepIndex + 1]" @click="currentStep =  steps[stepIndex + 1].name; isSidePanelOpen = true"
            >
              {{ selectLabelByStepIndex(stepIndex + 1) }}
              <IconSaxArrowRight2 class="w-4 h-4" />
            </Button>

            <ExportButton variant="gradient" v-else-if="userInfoStore.isAuthenticated" button-position="BelowTimeline">
              Export clip
              <IconSaxExportCircle class="w-4 h-4" />
            </ExportButton>
            <LoginDialog v-else>
              <button class="ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-indigo-500 px-4 py-2 text-white outline-none transition-colors hover:bg-indigo-600 focus:bg-indigo-600">
                <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
                Login
              </button>
            </LoginDialog>
          </div>
        </div>
      </section>
      <section class="editor-page__workspace relative z-[0] overflow-hidden shadow-inner">
        <WorkspaceWarning v-if="showWarning" @continue="showWarning = false;" />
        <template v-else>
          <slot name="router-view" />
          <RouterView />
        </template>
      </section>
    </div>
  </TooltipProvider>
</template>

<style scoped lang="scss">
.editor-page {
  display: grid;
  width: 100vw;
  height: 100vh;

  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto auto 1fr;
  grid-template-areas:
    "nav side-panel upgrade-banner"
    "nav side-panel workspace"
    "nav side-panel timeline";

  .editor-page__upgrade-banner {
    grid-area: upgrade-banner;
  }

  .editor-page__nav {
    grid-area: nav;
  }

  .editor-page__side-panel {
    grid-area: side-panel;
  }

  .editor-page__workspace {
    grid-area: workspace;
  }

  .editor-page__timeline {
    grid-area: timeline;
  }
}
</style>
