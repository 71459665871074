<script setup lang="ts">
import {
  type Transcript,
  type TranscriptWord,
  type TranscriptWordEffect,
  useCaptionsStore
} from '@/areas/editor/store/useCaptionsStore'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import IconSaxMagicStar from '@/components/Icons/iconsax/IconSaxMagicStar.vue'
import { Button } from '@/components/ui/button'
import IconSaxBlur from '@/components/Icons/iconsax/IconSaxBlur.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import type { CaptionPresetVariant } from '@/components/Captions/v3/CaptionPreset'
import HsbInput from '@/components/colors/HsbInput.vue'
import { v4 as uuid } from 'uuid'
import IconSaxActivity from '@/components/Icons/iconsax/IconSaxActivity.vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps<{
  caption: Transcript
  isNew: boolean
  isActive: boolean
}>();

const captionRef = ref<HTMLTextAreaElement | null>(null);

const captionsStore = useCaptionsStore();
const videoStore = useVideoStore();
const historyStore = useHistoryStore();

const pendingUpdates = ref<string | null>(null);

const setPendingUpdates = (caption: Transcript, event: Event) => {
  const inputElement = event.target as HTMLTextAreaElement;
  pendingUpdates.value = inputElement.value;
  autoResizeTextarea();
};

const commitChanges = (caption: Transcript, event: Event) => {

  // This means that the target is a newly added caption and the user has not typed anything.
  const target = event.target as HTMLTextAreaElement;
  if (target && target.value === '' && caption.words.length === 1 && caption.words[0].text === '') {
    return;
  }

  isEditingElement.value = null;

  setPendingUpdates(caption, event);

  const hasChanges = pendingUpdates.value !== caption.words.map(w => w.text).join(' ');
  if (hasChanges || pendingUpdates.value === '') {
    captionsStore.updateCaptionWordsById(caption.id, pendingUpdates.value);
    pendingUpdates.value = null;
    historyStore.transaction('CAPTIONS:EDIT_TEXT');
  }

  if (captionRef.value instanceof HTMLTextAreaElement) {
    captionRef.value.setSelectionRange(0, 0);
    captionRef.value.blur();
  }

  captionsStore.captionIdBeingEdited = null;
  captionsStore.wordIdBeingEdited = null;

  showToolbar.value = false;

  selectedWords.value = [];
};

const autoResizeTextarea = () => {
  if (captionRef.value instanceof HTMLTextAreaElement) {
    captionRef.value.style.height = 'auto'; // Reset height to recalculate
    captionRef.value.style.height = `${captionRef.value.scrollHeight}px`; // Set height based on scrollHeight
  }
};

watch([() => props.caption.words, () => captionRef.value], () => {
  nextTick(() => autoResizeTextarea()); // Ensure resizing on caption change
}, { immediate: true });

const isEditingElement = ref<HTMLTextAreaElement|null>(null);

const onFocus = (caption: Transcript, e: Event) => {

  isEditingElement.value = e.target as HTMLTextAreaElement;

  videoStore.playing = false;

  if (caption) {

    captionsStore.captionIdBeingEdited = caption?.id ?? null;

    // Set the wordIdBeingEdited to the last word so the video jumps to the end of the caption
    if (!captionsStore.wordIdBeingEdited) {
      captionsStore.wordIdBeingEdited = caption.words[caption.words.length - 1].id;
    }
  }

  if (caption && caption.words.length && captionsStore.baseOptions.grouping !== 'single') {
    const rendererCaption = captionsStore.findRendererCaptionByWordId(captionsStore.wordIdBeingEdited);
    if (rendererCaption) {
      const lastWord = rendererCaption.words[rendererCaption.words.length - 1];
      const middleOfWord = lastWord.start + (0.5 * (lastWord.end - lastWord.start));
      videoStore._currentTime = middleOfWord / 1000;
    }
  }
};

const onSelect = (caption: Transcript, event: Event) => {

  if (!isEditingElement.value || showColorPickerFor.value.length > 0) {
    return;
  }

  // This means that the target is a newly added caption and the user has not typed anything.
  const target = event.target as HTMLTextAreaElement;
  if (target && target.value === '' && caption.words.length === 1 && caption.words[0].text === '') {
    return;
  }

  if (isEditingElement.value === event.target) {

    setPendingUpdates(caption, event);

    const hasChanges = pendingUpdates.value !== caption.words.map(w => w.text).join(' ');
    if (hasChanges || pendingUpdates.value === '') {
      historyStore.transaction('CAPTIONS:EDIT_TEXT', () => {
        captionsStore.updateCaptionWordsById(caption.id, pendingUpdates.value ?? '');
      });
      pendingUpdates.value = null;
    }
  }

  const start = target.selectionStart;
  const end = target.selectionEnd;

  findAllTranscriptWordsInSelection(caption, start, end);

  setVideoCurrentTimeBasedOnSelection();

  if (selectedWords.value.length > 0) {

    const wordElementStart = document.getElementById(`word-${selectedWords.value[0].id}`);
    const wordElementEnd = document.getElementById(`word-${selectedWords.value[selectedWords.value.length - 1].id}`);

    if (wordElementStart && wordElementEnd) {

      const boundingClientRectStart = wordElementStart.getBoundingClientRect();
      const boundingClientRectEnd = wordElementEnd.getBoundingClientRect();

      toolbarLeft.value = (boundingClientRectStart.left + boundingClientRectEnd.left) / 2;
      toolbarTop.value = boundingClientRectStart.top - 40;

      showToolbar.value = selectedWords.value.length > 0 || (selectedWords.value.length > 0 && selectedWords.value.every(w => w.effects?.length > 0));
    }
  }
};

const setVideoCurrentTimeBasedOnSelection = () => {
  const word = selectedWords.value[selectedWords.value.length - 1];
  const middleOfWord = word.start + (0.5 * (word.end - word.start));
  videoStore._currentTime = middleOfWord / 1000;
};

const findAllTranscriptWordsInSelection = (caption: Transcript, start: number, end: number) => {

  const wholeCaptionAsString = caption.words.map(w => w.text).join(' ');

  const startMinusAmountOfSpaces = wholeCaptionAsString.slice(0, start).split(' ').length;
  const endMinusAmountOfSpaces = wholeCaptionAsString.slice(0, end).split(' ').length;

  // Set selectedWords to the words between and including startWord and endWord
  selectedWords.value = caption.words.filter((word, index) => {
    return index >= startMinusAmountOfSpaces - 1 && index <= endMinusAmountOfSpaces - 1;
  });
};

const currentTimeMs = computed(() => {
  return videoStore.currentTimeMs;
});

const isWordActive = (word: TranscriptWord) => {
  return word.start <= currentTimeMs.value && word.end >= currentTimeMs.value;
};

onMounted(() => {
  // check if new caption is empty and focus it
  if (props.isNew && captionRef.value) {
    nextTick(() => captionRef.value.focus());
  }
});

const toolbarRef = ref<HTMLDivElement | null>(null);

const showToolbar = ref(false);
const toolbarLeft = ref(0);
const toolbarTop = ref(0);

const selectedWords = ref<TranscriptWord[]>([]);

const deleteSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:DELETE_WORDS', () => {
      captionsStore.deleteWordsByIds(ids);
    });
  }

  showToolbar.value = false;
};

const removeEffectsFrom = (selectedWords: TranscriptWord[], type: string | null = null) => {

  const ids = selectedWords.map(w => w.id);

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:REMOVE_EFFECTS', () => {
      captionsStore.removeEffectsFromWordsByIds(ids, type);
    });
  }
};

const shakeSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'shake',
    animation: 'float-around',
    size: 'medium',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SHAKE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const supersizeSelectedWords = (selectedWords: TranscriptWord[]) => {

  const ids = selectedWords.map(w => w.id);

  const effect: TranscriptWordEffect = {
    id: uuid(),
    type: 'supersize',
    animation: captionsStore.baseOptions.animation,
    size: 'large',
  };

  if (ids.length > 0) {
    historyStore.transaction('CAPTIONS:ADD_SUPERSIZE_EFFECT', () => {
      captionsStore.addEffectToWordsByIds(ids, effect);
    });
  }
};

const colorPicker = ref<HTMLDivElement|null>(null);
const showColorPickerFor = ref<TranscriptWord[]>([]);

onClickOutside(colorPicker, () => {
  showColorPickerFor.value = [];
});

watch(() => showColorPickerFor.value, () => {
  if (showColorPickerFor.value.length > 0) {
    showToolbar.value = false;
  }
}, { immediate: true });

watch(() => showToolbar.value, () => {
  if (!showToolbar.value) {
    selectedWords.value = [];
  }
}, { immediate: true });

const currentColor = computed({
  get: () => {
    const firstCaptionVariant = showColorPickerFor.value?.[0]?.captionVariant as CaptionPresetVariant;
    if (firstCaptionVariant && !firstCaptionVariant.font?.color?.variant) {
      return firstCaptionVariant.font.color as string;
    } else {
      return captionsStore.baseCaptionPreset?.font?.color ?? '#ffffff';
    }
  },
  set: (value: string) => {
    commitColorChange(showColorPickerFor.value, value);
  }
})

const commitColorChange = (selectedWords: TranscriptWord[], color = '#ffffff') => {

  if (selectedWords.length === 0) {
    return;
  }

  captionsStore.updateWordsColor(selectedWords, color);
};
</script>

<template>
  <div
    ref="colorPicker"
    v-if="showColorPickerFor.length > 0"
    class="flex flex-col justify-center items-center fixed z-20 bg-surface-panel border border-surface-border rounded-md shadow-md px-4 py-2"
    :style="{
      top: toolbarTop + 'px',
      left: toolbarLeft + 'px'
    }"
    @click.prevent.stop
  >
    <HsbInput v-model:hex="currentColor" />
    <Button class="mt-2" size="sm" variant="outline" @click="showColorPickerFor = []">
      Close
    </Button>
  </div>

  <Transition
    :enter-from-class="'opacity-0 translate-y-2'"
    :leave-to-class="'opacity-0 -translate-y-2'"
    enter-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
    leave-active-class="motion-safe:transition-[transform,_opacity] duration-1000"
  >
    <div
      v-if="showToolbar"
      ref="toolbarRef"
      class="fixed z-20 bg-surface-panel border border-surface-border rounded-md shadow-md flex -translate-x-1/4"
      @mousedown.prevent
      :style="{
        top: toolbarTop + 'px',
        left: toolbarLeft + 'px'
      }"
    >
      <Tooltip disable-hoverable-content>
        <TooltipTrigger>
          <Button
            tabindex="-1"
            variant="ghost"
            size="sm"
            @mousedown.prevent
            @click="showColorPickerFor = selectedWords"
          >
            <IconSaxBlur class="stoke-[1] h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent class="font-light">
          {{ selectedWords.length > 1 ? 'Change the color of selected words' : 'Change color' }}
        </TooltipContent>
      </Tooltip>

      <div class="h-6 w-px bg-zinc-200" />

      <template v-if="selectedWords.every(w => w.effects?.some(e => e.type === 'supersize'))">
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button
              tabindex="-1"
              variant="ghost"
              size="sm"
              @mousedown.prevent
              @click.prevent.stop="removeEffectsFrom(selectedWords, 'supersize')"
            >
              <IconSaxCloseCircle class="stoke-[1] h-4 w-4 text-red-500" />
            </Button>
          </TooltipTrigger>
          <TooltipContent class="font-light">
            {{ selectedWords.length > 1 ? 'Remove all effects from selected words' : 'Remove supersize' }}
          </TooltipContent>
        </Tooltip>
      </template>
      <template v-else>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button
              tabindex="-1"
              variant="ghost"
              size="sm"
              @mousedown.prevent
              @click.prevent.stop="supersizeSelectedWords(selectedWords)"
            >
              <IconSaxMagicStar class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent class="font-light">
            {{ selectedWords.length > 1 ? 'Supersize selected words' : 'Supersize' }}
          </TooltipContent>
        </Tooltip>
      </template>

      <div class="h-6 w-px bg-zinc-200" />

      <template v-if="selectedWords.every(w => w.effects?.some(e => e.type === 'shake'))">
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button
              tabindex="-1"
              variant="ghost"
              size="sm"
              @mousedown.prevent
              @click.prevent.stop="removeEffectsFrom(selectedWords, 'shake')"
            >
              <IconSaxCloseCircle class="stoke-[1] h-4 w-4 text-red-500" />
            </Button>
          </TooltipTrigger>
          <TooltipContent class="font-light">
            {{ selectedWords.length > 1 ? 'Remove shake from selected words' : 'Remove shake' }}
          </TooltipContent>
        </Tooltip>
      </template>
      <template v-else>
        <Tooltip disable-hoverable-content>
          <TooltipTrigger>
            <Button
              tabindex="-1"
              variant="ghost"
              size="sm"
              @mousedown.prevent
              @click.prevent.stop="shakeSelectedWords(selectedWords)"
            >
              <IconSaxActivity class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent class="font-light">
            {{ selectedWords.length > 1 ? 'Shake selected words' : 'Shake' }}
          </TooltipContent>
        </Tooltip>
      </template>

      <div class="h-6 w-px bg-zinc-200" />

      <Tooltip disable-hoverable-content>
        <TooltipTrigger>
          <Button
            tabindex="-1"
            variant="ghost"
            size="sm"
            @mousedown.prevent
            @click.prevent.stop="deleteSelectedWords(selectedWords)"
          >
            <IconSaxTrash class="stoke-[1] h-4 w-4 text-red-500" />
          </Button>
        </TooltipTrigger>
        <TooltipContent class="font-light">
          {{ selectedWords.length > 1 ? 'Delete selected words' : 'Delete word' }}
        </TooltipContent>
      </Tooltip>
    </div>
  </Transition>

  <div class="flex relative w-full items-center justify-center">

    <div class="absolute top-0 leading-[25px] left-0 p-1.5 w-full h-full bg-transparent z-10 pointer-events-none opacity-100">
      <div
        v-for="word in caption.words"
        :id="'word-'+word.id"
        :key="word.id"
        class="inline rounded-md font-light"
      >
        <span
          class="relative transition-[opacity,colors] opacity-0 inline-block before:rounded-[3px] before:absolute before:-inset-x-0.5 before:inset-y-0.5 before:z-[-1]"
          :class="{
            'before:bg-purple-500 text-white opacity-100': isWordActive(word) && !isEditingElement,
            'underline underline-offset-4 decoration-2 decoration-primary opacity-100 text-transparent cursor-pointer': word.effects?.length > 0,
            'underline underline-offset-4 decoration-2 decoration-zinc-900 opacity-100 text-transparent cursor-pointer': selectedWords.includes(word),
            '!opacity-100 text-transparent': word.captionVariant?.font?.color,
          }"
          :style="{
            'border-bottom': '2px solid' + word.captionVariant?.font?.color,
          }"
        >
          {{ word.text }}
        </span>
        {{ ' ' }}
      </div>
    </div>

    <textarea
      :id="'caption-'+caption.id"
      ref="captionRef"
      rows="1"
      class="bg-transparent w-full leading-[25px] p-1.5 font-light outline-0 resize-none hover:bg-blue-50 dark:hover:bg-surface-panel-100 dark:bg-surface-panel transition-[background-color,transform,border-color] relative"
      :class="{
        '!bg-blue-50 dark:!bg-surface-panel-100': isActive,
      }"
      :value="caption.words.map(w => w.text).join(' ')"
      @keydown.enter.exact="e => commitChanges(caption, e)"
      @keydown.shift.enter.stop
      @input="e => setPendingUpdates(caption, e)"
      @blur="e => commitChanges(caption, e)"
      @focus="e => onFocus(caption, e)"
      @click="e => onSelect(caption, e)"
      @select="e => onSelect(caption, e)"
      placeholder="Type your new caption here.."
    />
  </div>
</template>

<style scoped lang="scss">
textarea {
  overflow: hidden; /* Prevent scrollbar from appearing */
}
</style>
