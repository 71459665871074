import { useEventListener, useRafFn } from '@vueuse/core'
import { ref, watch, onBeforeUnmount, type Ref, onUnmounted, computed, onMounted } from 'vue';
import { WorkerRenderer } from '@/modules/SLVideoplayer/canvas/WorkerRenderer'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { debounce } from 'lodash-es'
import { useRendererData } from '@/areas/editor/store/useRendererData'

const renderer = ref<WorkerRenderer | null>(null)
const canvas = computed(() => renderer.value?.view ?? null)

export function useCreateRenderer(
  container: Ref<HTMLElement | null>,
  containerWidth: Ref<number>, containerHeight: Ref<number>
) {

  const videoStore = useVideoStore()

  const resizeToVideo = debounce(() => {
    if (renderer.value && container.value) {
      renderer.value.resizeTo(container.value)
    }
  }, 100)

  const { rendererCrops } = useRendererData()
  
  interface RenderElements {
    container: HTMLElement;
    canvas: OffscreenCanvas;
    video: HTMLVideoElement;
    audio: HTMLAudioElement;
  }

  async function loadRenderElements() {
    return new Promise<RenderElements>((resolve) => {
      watch([() => container.value, () => videoStore.canvas, () => videoStore.videoElement, () => videoStore.audioElement], (
        [container, canvas, video, audio ]
      ) => {
        if (container && canvas && video && audio) {
          return resolve({ container, canvas, video, audio })
        }
      }, { immediate: true });
    })
  }

  onMounted(async () => {
   
    const elements = await loadRenderElements();
    
    renderer.value = new WorkerRenderer(elements.container.clientWidth, elements.container.clientHeight)
    renderer.value.setBackground(elements.canvas)
    renderer.value.setCrops(rendererCrops, elements.video, elements.canvas)
    renderer.value.setAudio(elements.audio)
    renderer.value.mixAudio()
    resizeToVideo()

    elements.container.appendChild(renderer.value.view)
  });

  useRafFn(() => {
    if (renderer.value && videoStore.videoElement) {
      renderer.value.syncAudioToVideo(videoStore.videoElement);
    }
  });

  watch([containerWidth, containerHeight], resizeToVideo)

  onBeforeUnmount(() => {
    if (renderer.value) {
      renderer.value.destroy()
    }
  })

  useEventListener(videoStore.videoElement, 'canplay', () => {
    if (renderer.value) {
      renderer.value.ticker.update()
    }
  })

  useEventListener(videoStore.videoElement, 'loadeddata', resizeToVideo)

  useEventListener(videoStore.videoElement, 'play', () => {
    if (renderer.value) {
      renderer.value.ticker.start()
    }
  })

  useEventListener(videoStore.videoElement, 'pause', () => {
    if (renderer.value) {
      renderer.value.ticker.pause()
    }
  })
  
  onUnmounted(() => {
    if (renderer.value) {
      renderer.value.destroy()
      renderer.value = null
    }
  })
}

export function usePreviewCanvas() {
  return { canvas, renderer }
}
