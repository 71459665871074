<script setup lang="ts">
import { ref, capitalize, computed, watch } from 'vue'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { usePrevious } from '@vueuse/core'
import CaptionEditorTabsStylesTab from '@/areas/editor/views/captions/tabs/CaptionEditorTabsStylesTab.vue'
import CaptionEditorTabsEffectsTab from '@/areas/editor/views/captions/tabs/CaptionEditorTabsEffectsTab.vue'
import CaptionEditorTabsAnimateTab from '@/areas/editor/views/captions/tabs/CaptionEditorTabsAnimateTab.vue'
import CaptionEditorTabsPresetsTab from '@/areas/editor/views/captions/tabs/CaptionEditorTabsPresetsTab.vue'
import CaptionEditorV2 from '@/areas/editor/views/captions/v2/CaptionEditorV2.vue'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

const tab = ref('text');
const tabs = ['text', 'styles', 'animations', 'effects', 'presets'];
const previous = usePrevious(tab, 'text');
const direction = computed(() => Math.sign(tabs.indexOf(tab.value) - tabs.indexOf(previous.value)));

const captionsStore = useCaptionsStore();

watch(() => captionsStore.captionIdBeingEdited, (editingCaption) => {
  if (editingCaption) {
    tab.value = 'text';
  }
}, { immediate: true });
</script>

<template>
  <section class="flex flex-col 2xl:gap-2">
    <div class="flex gap-2 px-5 items-center">
      <h2 class="text-xl font-semibold leading-snug font-title -mt-[1px]">AI-Powered Captions</h2>
      <span class="-mt-0.5 top-2.5 left-2.5 rounded-full font-semibold text-xs uppercase px-2 py-1 bg-green-700 text-background">
        BETA
      </span>
    </div>

    <Tabs v-model="tab">
      <TabsList class="mx-4 mt-2">
        <TabsTrigger class="px-2 2xl:px-3" v-for="tab of tabs" :key="tab" :value="tab">
          {{ capitalize(tab) }}
        </TabsTrigger>
      </TabsList>
      <Transition appear
        :enter-from-class="direction === -1 ? 'opacity-0 -translate-x-12' : 'opacity-0 translate-x-12'"
        :leave-to-class="direction === -1 ? 'opacity-0 translate-x-12' : 'opacity-0 -translate-x-12'"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <section :key="tab" class="absolute w-full mt-2">
          <TabsContent value="text">
            <CaptionEditorV2 />
          </TabsContent>
          <TabsContent value="styles">
            <CaptionEditorTabsStylesTab />
          </TabsContent>
          <TabsContent value="effects">
            <CaptionEditorTabsEffectsTab />
          </TabsContent>
          <TabsContent value="animations">
            <CaptionEditorTabsAnimateTab />
          </TabsContent>
          <TabsContent value="presets">
            <CaptionEditorTabsPresetsTab />
          </TabsContent>
        </section>
      </Transition>
    </Tabs>
  </section>
</template>

<style scoped lang="scss">

</style>
