<script setup lang="ts">
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'
import { type Transcript, type TranscriptWord, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { computed } from 'vue'

const props = defineProps<{
  caption: Transcript
  captionIndex: number
}>();

const captionsStore = useCaptionsStore();
const videoStore = useVideoStore();
const editorFocusStore = useEditorFocusStore();
const historyStore = useHistoryStore();

const strip = useStripPunctuation();

const findNearestWordEndMsOnTheLeftInAllCaptions = (captionIndex: number, wordIndex: number) => {
  if (wordIndex > 0) {
    return props.caption.words[wordIndex - 1].end;
  } else if (captionIndex > 0) {
    return captionsStore.entities[captionIndex - 1].words[captionsStore.entities[captionIndex - 1].words.length - 1].end;
  } else {
    return 0;
  }
};

const findNearestWordStartMsOnTheRightInAllCaptions = (captionIndex: number, wordIndex: number) => {
  if (wordIndex < props.caption.words.length - 1) {
    return props.caption.words[wordIndex + 1].start;
  } else if (captionIndex < captionsStore.entities.length - 1) {
    return captionsStore.entities[captionIndex + 1].words[0].start;
  } else {
    return videoStore._duration * 1000;
  }
};

const minStartMs = (captionIndex: number, wordIndex: number) => {
  return findNearestWordEndMsOnTheLeftInAllCaptions(captionIndex, wordIndex) ?? 0;
};

const maxEndMs = (captionIndex: number, wordIndex: number) => {
  return findNearestWordStartMsOnTheRightInAllCaptions(captionIndex, wordIndex) ?? videoStore._duration * 1000;
};

const focus = (word: TranscriptWord) => {
  editorFocusStore.setFocus(FocusTypes.CAPTION, word.id);
};

const currentWord = computed(() => {
  return props.caption.words.find(word => videoStore.preciseCurrentTime * 1000 >= word.start && videoStore.preciseCurrentTime * 1000 <= word.end);
});

const isMobile = useIsMobile();

const onMouseEnter = (wordId: string) => {
  captionsStore.captionIdHovered = props.caption.id;
  captionsStore.wordIdBeingHovered = wordId;
};

const onMouseLeave = () => {
  captionsStore.captionIdHovered = null;
  captionsStore.wordIdBeingHovered = null;
};

const editWord = (word: TranscriptWord) => {
  activate(word);
  captionsStore.captionIdBeingEdited = props.caption.id;
  captionsStore.wordIdBeingEdited = word.id;
};

const activate = (word: TranscriptWord) => {

  editorFocusStore.setFocus(FocusTypes.CAPTION, word.id);

  captionsStore.captionIdHovered = props.caption.id;
  captionsStore.wordIdBeingHovered = word.id;

  if (videoStore.playing) {
    videoStore.playing = false;
  }

  videoStore._currentTime = (word.start + (0.5 * (word.end - word.start))) / 1000;
};

const updateTimingsById = (word: TranscriptWord, start: number, end: number) => {
  captionsStore.updateTimingsById(word.id, start, end);
};

const deleteWord = (word: TranscriptWord) => {
  captionsStore.deleteWordById(word.id);
};
</script>

<template>
  <TimeLineTrackNode
    v-for="(word, wordIndex) in caption.words"
    class="border-gray-300 dark:border-zinc-600 dark:bg-zinc-300 bg-white hover:border-2 hover:border-gray-900 dark:hover:border-gray-500 group cursor-grab rounded-lg h-full z-10 border ring-transparent transition-colors duration-75"
    :class="{
      '!border-2 !border-company-primary-200 !bg-purple-50 dark:!bg-[#7e6799]': word.id === currentWord?.id || captionsStore.captionIdBeingEdited === caption.id,
    }"
    :id="word.id"
    :key="word.id"
    :start-ms="word.start"
    :end-ms="word.end"
    :min-start-ms="minStartMs(captionIndex, wordIndex)"
    :max-end-ms="maxEndMs(captionIndex, wordIndex)"
    :min-duration-ms="50"
    seek-behavior="seek"
    :enable-tooltip="!isMobile"
    :active="editorFocusStore.focus?.type === 'caption' && editorFocusStore.focus?.key === word.id"
    @dragStart="focus(word)"
    @resizeStop="(start, end) => historyStore.transaction('WORD:UPDATE_DURATION', () => updateTimingsById(word, start, end))"
    @dragStop="(start, end) => historyStore.transaction('WORD:UPDATE_START_TIME',() => updateTimingsById(word, start, end))"
    @activated="activate(word)"
    @dblclick="editWord(word)"
    @keydown.delete="deleteWord(word)"
    @mouseenter="onMouseEnter(word.id)"
    @mouseleave="onMouseLeave"
  >
    <div
      v-if="word.effects?.length > 0"
      class="absolute h-[3px] -top-2 rounded-md w-full bg-company-primary-50"
    />

    <template #tooltip-content>
      <span class="truncate text-center -mx-1/2 text-gray-950 dark:text-white">
        {{ strip(word) }}
      </span>
    </template>
    <div
      class="font-light grid h-full w-full place-items-center overflow-hidden rounded-lg text-sm text-gray-950 dark:text-white"
      :class="{
        '!text-purple-800 dark:!text-white': word.id === currentWord?.id || captionsStore.captionIdBeingEdited === caption.id,
      }"
    >
      <span class="truncate text-center -mx-1/2">
        {{ strip(word) }}
      </span>
    </div>
    <template #handles>
      <div class="flex h-full flex-row gap-0.5 px-1 py-1">
        <div class="h-full w-1 rounded bg-yellow-600" />
        <div class="h-full w-1 rounded bg-yellow-600" />
      </div>
    </template>
  </TimeLineTrackNode>
</template>

<style scoped lang="scss">

</style>
