import { isMacOs } from '@/data/isMacOs';
import { merge, noop } from 'lodash-es';

const listeners = new Set<{ combination: Bindings, callback: (event: KeyboardEvent) => void, config: KeybindingConfig }>();

export interface KeybindingConfig {
  prevent?: boolean;
}

export function onKeyCombination(combination: Bindings, callback: (event: KeyboardEvent) => void, config: KeybindingConfig = {}) {
  listeners.add({ combination, callback, config });
  return () => listeners.delete({ combination, callback, config });
}

export function useKeyCombination(combination: Bindings, callback: (event: KeyboardEvent) => void, config: KeybindingConfig) {
  let remove = noop;
  onMounted(() => remove = onKeyCombination(combination, callback, config))
  onUnmounted(() => remove());
}

window.addEventListener('keydown', (event) => {
  for (const listener of listeners) {
    if (isKeyCombinationPressed(event, listener.combination)) {
      if (listener.config.prevent) event.preventDefault();
      listener.callback(event);
    }
  }
});

export function isKeyCombinationPressed(event: KeyboardEvent, name: Bindings) {

  const binding = bindings[name];
  if (!binding) {
    console.warn(`Unknown key binding "${name}"`);
    return false;
  }

  const keyCombination = parseKeyCombinationFromEvent(event);
  return binding.includes(keyCombination);
}

const macOsBindings = {
  'undo': ['cmd+z'],
  'redo': ['cmd+shift+z', 'cmd+y'],
  'save': ['cmd+s'],
  'zoom-in': ['cmd+='],
  'zoom-out': ['cmd+-'],
  'go-to-start': ['cmd+left', 'home'],
  'go-to-end': ['cmd+right', 'end'],
};

const unixBindings = {
  'undo': ['ctrl+z'],
  'redo': ['ctrl+y'],
  'save': ['ctrl+s'],
  'zoom-in': ['ctrl+='],
  'zoom-out': ['ctrl+-'],
  'go-to-start': ['ctrl+left', 'home'],
  'go-to-end': ['ctrl+right', 'end'],
};

const sharedBindings = {
  'up': ['up', 'w'],
  'down': ['down', 's'],
  'left': ['left', 'a'],
  'right': ['right', 'd'],
  'play': ['space', 'k'],
  'pause': ['space', 'k'],
  'skip-backward': ['j'],
  'skip-forward': ['l'],
  'skip-forward-10': ['shift+l'],
  'skip-backward-10': ['shift+j'],
  'skip-forward-1': ['alt+l'],
  'skip-backward-1': ['alt+j'],
  'resize-from-center': ['alt'],
  'resize-maintain-aspect-ratio': ['shift'],
  'resize-maintain-aspect-ratio-from-center': ['shift+alt'],
  'trim-start': ['['],
  'trim-end': [']'],
  'split': ['s'],
  'delete': ['delete', 'backspace'],
  'escape': ['esc'],
};

export type Bindings = keyof ((typeof macOsBindings | typeof unixBindings) & typeof sharedBindings);
export const bindings = merge(isMacOs ? macOsBindings : unixBindings, sharedBindings) as Record<Bindings, string[]>;

export function parseKeyCombinationFromEvent(event: KeyboardEvent) {

  const keys = [];
  if (isMacOs) {
    if (event.metaKey) keys.push('cmd');
  } else {
    if (event.ctrlKey) keys.push('ctrl');
  }

  if (event.altKey) keys.push('alt');
  if (event.shiftKey) keys.push('shift');
  
  if (event.key !== 'Meta' && event.key !== 'Control' && event.key !== 'Alt' && event.key !== 'Shift') {
    keys.push(normalizeKey(event.key));
  }

  return keys.join('+');
}

function normalizeKey(key: string): string {
  const keyMap: Record<string, string> = {
    ArrowUp: 'up',
    ArrowDown: 'down',
    ArrowLeft: 'left',
    ArrowRight: 'right',
    Meta: 'cmd',
  };
  return keyMap[key] || key.toLowerCase();
}
